export default {
  namespaced: true,
  state: {
    messagePrefix: "",
    messageToAdmin: "",
  },
  mutations: {
    SET_MESSAGE_TO_ADMIN(state, message) {
      state.messageToAdmin = message;
    },
    CLEAR_MESSAGE(state) {
      state.messageToAdmin = "";
    },
    SET_MESSAGE_PREFIX(state, prefix) {
      state.messagePrefix = prefix;
    },
  },
  getters: {
    getMessage(state) {
      return state.messageToAdmin;
    },
    getPrefix(state) {
      return state.messagePrefix;
    },
  },
};
