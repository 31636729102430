import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util';

export class LookUpService extends BaseService {
  static async getLookUp(tag) {
    try {
      const response = await this.request({ auth: false }).get(`/lookup/${tag}`)
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}