var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-col",
    { staticClass: "cart-content", attrs: { cols: "12" } },
    [
      _c(
        "b-row",
        { staticStyle: { height: "100%" }, attrs: { "align-v": "stretch" } },
        [
          _vm.productList && _vm.productList.length == 0
            ? _c("b-col", { attrs: { cols: "12", "align-self": "center" } }, [
                _c("h2", { staticClass: "heading mb-1 text-center mb-3" }, [
                  _vm._v(" Oops! Your cart is empty. "),
                ]),
                _c(
                  "p",
                  { staticClass: "text-center" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "primary" },
                        on: { click: _vm.redirectToTreatments },
                      },
                      [_vm._v("View Our Treatments")]
                    ),
                  ],
                  1
                ),
              ])
            : _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-row",
                    {
                      staticStyle: { height: "100%" },
                      attrs: { "align-v": "stretch" },
                    },
                    [
                      _c(
                        "b-col",
                        { attrs: { "align-self": "start" } },
                        [
                          _c(
                            "h3",
                            {
                              staticClass: "bold-font text-right mt-3",
                              staticStyle: { "letter-spacing": "0.16em" },
                            },
                            [_vm._v(" CART ")]
                          ),
                          _c("cartIcon", {
                            staticClass: "mt-5",
                            attrs: { number: "1" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          attrs: {
                            cols: "12 mt-3 mb-3",
                            "align-self": "center",
                          },
                        },
                        [
                          _vm._l(_vm.productList, function (item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "product" },
                              [
                                _c("b-icon", {
                                  staticClass: "remove cursor",
                                  attrs: { icon: "x", "font-scale": "1.5" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeProduct(item.index)
                                    },
                                  },
                                }),
                                _c("div", { staticClass: "product-img" }, [
                                  _c("img", {
                                    attrs: {
                                      src: item.image
                                        ? item.image
                                        : "https://via.placeholder.com/150",
                                      alt: item.name,
                                    },
                                  }),
                                ]),
                                _c("div", { staticClass: "product-content" }, [
                                  _c(
                                    "h2",
                                    {
                                      staticClass: "heading mb-2 color-inherit",
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  ),
                                  _c("p", { staticClass: "small-text" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(item.dosage) +
                                        " — $" +
                                        _vm._s(item.selectedPricing.price) +
                                        " "
                                    ),
                                  ]),
                                  _c(
                                    "p",
                                    {
                                      directives: [
                                        {
                                          name: "line-clamp",
                                          rawName: "v-line-clamp",
                                          value: 2,
                                          expression: "2",
                                        },
                                      ],
                                      staticClass: "pt-2 pb-2 mb-0 small-text",
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.description) + " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "quantity mt-2" },
                                    [
                                      _c("b-icon", {
                                        staticClass: "cursor",
                                        attrs: { icon: "dash" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.decrease(item.index)
                                          },
                                        },
                                      }),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: item.quantity,
                                            expression: "item.quantity",
                                          },
                                        ],
                                        attrs: {
                                          type: "number",
                                          min: "0",
                                          placeholder: "0",
                                        },
                                        domProps: { value: item.quantity },
                                        on: {
                                          change: function ($event) {
                                            return _vm.update(
                                              item.index,
                                              item.quantity
                                            )
                                          },
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              item,
                                              "quantity",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _c("b-icon", {
                                        staticClass: "cursor",
                                        attrs: { icon: "plus" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.increase(item.index)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            )
                          }),
                          _c(
                            "b-list-group",
                            {
                              staticClass: "product-amount",
                              attrs: { flush: "" },
                            },
                            [
                              _c(
                                "b-list-group-item",
                                {
                                  staticClass: "d-flex justify-content-between",
                                },
                                [
                                  _vm._v(" Order Sub-total "),
                                  _c("span", [
                                    _vm._v("$" + _vm._s(_vm.subTotal)),
                                  ]),
                                ]
                              ),
                              _c(
                                "b-list-group-item",
                                {
                                  staticClass: "d-flex justify-content-between",
                                },
                                [
                                  _c("b", { staticClass: "bold-font" }, [
                                    _vm._v("Grand Total"),
                                  ]),
                                  _c("span", [_vm._v("$" + _vm._s(_vm.total))]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                      _c(
                        "b-col",
                        { staticClass: "pb-4", attrs: { "align-self": "end" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                pill: "",
                                block: "",
                                variant: "primary",
                              },
                              on: { click: _vm.checkState },
                            },
                            [_vm._v("Next")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }