<template>
  <b-col class="cart-billing">
    <b-row align-v="stretch" style="height: 100%">
      <b-col cols="12" align-self="start">
        <h3 class="bold-font text-right mt-3" style="letter-spacing: 0.16em;">
          CHECKOUT
        </h3>
        <cartIcon number="4" class="mt-5" />
      </b-col>
      <b-col cols="12" align-self="center">
        <h2 class="heading mb-1 text-center">You’re almost there</h2>
        <p class="text-center">
          Add payment method and we’re ready to ship your treatment.
        </p>
        <b-alert :show="alert.show" :variant="alert.type">{{
          alert.message
        }}</b-alert>
        <div
          class="product"
          v-for="(item, index) in productList"
          v-bind:key="index"
        >
          <div class="product-img">
            <img :src="item.image" alt="" />
          </div>
          <div class="product-content">
            <h3 class="heading mb-0">{{ item.name }}</h3>
            <h5 class="">
              {{ item.dosage }} — ${{ item.selectedPricing.price }}
            </h5>
            <p class="mb-0">
              ${{ item.selectedPricing.price * item.quantity }}
            </p>
          </div>
        </div>

        <b-list-group flush class="product-amount">
          <b-list-group-item class="d-flex justify-content-between">
            Order Sub-total
            <span>${{ subtotal }}</span>
          </b-list-group-item>
          <b-list-group-item class="d-flex justify-content-between">
            Tax
            <span>${{ taxamount }}</span>
          </b-list-group-item>
          <b-list-group-item class="d-flex justify-content-between">
            <b class="bold-font">Grand Total</b>
            <span>${{ totalamount }}</span>
          </b-list-group-item>
        </b-list-group>

        <hr />

        <div>
          <span class="bold-font mb-3">Pay with</span>
          <!-- Sir Mariwin update mo na lang toh this is to show the UI only alam ko may innintegrate ka sa stripe regarding dito :) -->
          <b-row>
            <b-col cols="12" lg="12">
              <div role="group" class="mb-3">
                <label class="input-label" for="cardNumber"
                  >Credit or Debit card*:</label
                >
                <div ref="card"></div>
              </div>
            </b-col>
          </b-row>
          <b-form-checkbox
            id="sameAsShippingAddress"
            v-model="notSame"
            name="bill"
          >
            <span class="d-block mt-2"
              >My billing information is the same as my shipping
              information</span
            >
          </b-form-checkbox>

          <!-- Billing Address -->
          <template v-if="!notSame">
            <p class="mt-6 mb-4 bold-font">Billing Address</p>
            <div role="group" class="mb-3">
              <label class="input-label" for="billingAddress1"
                >Street Address:</label
              >
              <b-form-input
                class="pl-0 fs-mask"
                id="billingAddress1"
                v-model="billingAddress1"
                type="text"
              ></b-form-input>
            </div>

            <div role="group" class="mb-3">
              <label class="input-label" for="billingAddress2"
                >Apartment/Suite:</label
              >
              <b-form-input
                id="billingAddress2"
                class="pl-0 fs-mask"
                v-model="billingAddress2"
                type="text"
              ></b-form-input>
            </div>

            <div role="group" class="mb-3">
              <label class="input-label" for="billingState">State:</label>
              <v-select
                class="cart-state fs-mask"
                append-to-body
                :calculate-position="withPopper"
                v-model="billingState"
                label="text"
                :options="billingStateoptions"
              >
                <!-- :reduce="(billingStateoptions) => billingStateoptions.value" -->
                <template #open-indicator="{ attributes }">
                  <b-icon v-bind="attributes" icon="chevron-down"></b-icon>
                </template>
              </v-select>
            </div>

            <b-row>
              <b-col cols="12" md="6">
                <div role="group" class="mb-3">
                  <label class="input-label" for="billingCity">City:</label>
                  <b-form-input
                    class="pl-0 fs-mask"
                    id="billingCity"
                    v-model="billingCity"
                    type="text"
                  ></b-form-input>
                </div>
              </b-col>
              <b-col cols="12" md="6">
                <div role="group" class="mb-3">
                  <label class="input-label" for="billingZipCode"
                    >Zip Code:</label
                  >
                  <b-form-input
                    class="pl-0 fs-mask"
                    id="billingZipCode"
                    v-model="billingZipCode"
                    type="text"
                  ></b-form-input>
                </div>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <label for="billingPhone" class="input-label">Phone No.</label>
                <b-form-input
                  class="pl-0 fs-mask"
                  id="billingPhone"
                  v-model="billingPhone"
                  type="text"
                  :formatter="formatPhoneNumber"
                ></b-form-input>
              </b-col>
            </b-row>
          </template>
        </div>

        <!-- <template v-else>
          <h3 class="bold mt-5">Shipping Information</h3>
          <p>
            1928 Consectetur 66th St., N/A <br />
            California, Malibu, 606
          </p>
          <hr class="mt-4 mb-4" />
          <h3 class="bold">Billing Information</h3>
          <p>Visa ending 6060</p>
        </template> -->
      </b-col>
      <b-col cols="12" align-self="end">
        <b-button
          pill
          block
          variant="link"
          class="mt-3 mb-3 "
          @click="$emit('set-screen', 'shipping')"
          >Back</b-button
        >

        <b-button
          :disabled="dataloading"
          pill
          block
          variant="primary"
          class="mt-3 mb-3"
          @click="proceedCheckout"
          >{{ buttonText }}</b-button
        >
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import config from "../../../config";
import popper from "@/assets/js/popper.js";
import cartIcon from "@/components/cart/CartIcon";
import mixins from "@/components/form/mixins";
// Services
import { PaymentService } from "@/services/payment.service";
import { TransactionService } from "@/services/transaction.service";
import { StripeService } from "@/services/stripe.service";
import { UsersService } from "@/services/users.service";
import { LookUpService } from "@/services/lookup.service";

export default {
  components: {
    cartIcon,
  },
  mixins: [popper, mixins],
  components: {
    cartIcon,
  },
  data() {
    return {
      alert: {
        show: false,
        message: "",
        type: "warning",
      },
      stripe: undefined,
      card: undefined,
      dataloading: false,
      productList: [],
      notSame: false,
      billingAddress1: "",
      billingAddress2: "",
      billingState: "",
      billingCity: "",
      billingZipCode: "",
      billingStateoptions: [],
      billingPhone: "",

      checkoutData: { checkout: [] },
      orderDetail: {},
      orderSummary: {},
      subtotal: 0,
      taxamount: 0,
      taxrate: 0,
      totalamount: 0,
    };
  },
  computed: {
    buttonText() {
      return this.dataloading ? "Please wait" : "Check out";
    },
  },
  methods: {
    async computeTax() {
      try {
        let res = await TransactionService.computeTax(this.checkoutData);
        let addon = [];
        let desired = [];
        if (res && res.data) {
          const { orderdetail, ordersummary } = res.data;
          this.orderSummary = ordersummary;
          this.subtotal = ordersummary.subtotal;
          this.taxamount = ordersummary.taxamount.toFixed(2);
          this.totalamount = ordersummary.totalamount.toFixed(2);
        }
        // this.desiredAddon = addon;
        // this.desiredPlan = desired;
      } catch (error) {
        // console.log("Error", error);
      }
    },
    showAlert(msg) {
      this.alert.message = msg;
      this.alert.show = true;
    },
    async proceedCheckout() {
      this.alert.show = false;
      this.dataloading = true;
      this.shippingAddress = false;
      let _s = this;
      const { token, error } = await _s.stripe.createToken(_s.card);

      if (token) {
        await this.updatePayment(token);
        await this.updateCheckout();
      } else {
        this.showAlert(error.message);
        this.dataloading = false;
      }
    },
    async updatePayment(token) {
      this.alert.show = false;
      try {
        let data = {
          source: token.id,
        };

        let res = await PaymentService.updatePayment(data);
        if (res && res.data) {
          //console.log("payment ", res.data);
        }
      } catch (error) {
        if (error.message) {
          this.dataloading = false;
          this.showAlert(error.message);
        }
      }
    },
    async updateCheckout() {
      try {
        let token = window.localStorage.getItem("accessToken");
        let params = {
          token: token ? token : null,
          sessionId: window.localStorage.getItem("sessionId"),
          products: window.localStorage.getItem("cart"),
          event: "checkout",
        };

        let billingAddressData = {
          sameaddress: this.notSame,
        };
        // Billing address handling
        if (!this.notSame) {
          billingAddressData.billingaddress = {
            address1: this.billingAddress1,
            address2: this.billingAddress2,
            zipcode: this.billingZipCode,
            country: "US",
            city: this.billingCity,
            state: this.billingState.value,
            phoneno: this.billingPhone,
          };
        }
        try {
          // console.log(billingAddressData)
          const res = await UsersService.addBillingAddress(billingAddressData);
          if (res && res.data) {
            let checkout = await TransactionService.checkout(this.checkoutData);
          }
        } catch (err) {
          throw err;
          return;
        }

        this.billingAddress = false;
        this.dataloading = false;
        window.parent.postMessage(params, process.env.VUE_APP_WEBSITE_URL);
        this.$emit("set-screen", "checkout-complete");
      } catch (error) {
        this.dataloading = false;
        if (error.message) {
          this.showAlert(error.message);
        }
      }
    },
  },
  async mounted() {
    // text-value
    // State loading
    const { data } = await LookUpService.getLookUp("state");
    for (let item of data) {
      this.billingStateoptions.push({
        text: item.name,
        value: item.id,
      });
    }
    // Cart loading
    this.productList = JSON.parse(window.localStorage.getItem("cart"));
    for (let item of this.productList) {
      this.checkoutData.checkout.push({
        productid: item.id,
        pricingid: item.selectedPricing.pricingid,
        quantity: item.quantity,
      });
    }
    this.stripe = await Stripe(process.env.VUE_APP_STRIPE_CLIENT_KEYS);
    this.card = this.stripe.elements().create("card", {
      hidePostalCode: true,
    });
    this.card.mount(this.$refs.card);

    this.computeTax();
  },
};
</script>
