import _ from "lodash";
import $router from "../router";
import axios from "axios";
/**
 * Return message for HTTP status code
 * @param {number} status - HTTP status code
 * @returns {string} Message of network operation
 */
let API_URL = process.env.VUE_APP_API_URL;
function _getStatusMessage(status) {
  let message = "";
  switch (status) {
    case 200:
      message = "All done. Request successfully executed";
      break;
    case 201:
      message = "Data successfully created";
      break;
    case 400:
      message = "Bad Request";
      break;
    case 401:
      message = "Need auth";
      break;
    case 404:
      message = "Not found";
      break;
    case 503:
      message = "Service unavailable. Try again later";
      break;
    default:
      message = "Something wrong. Client default error message";
      break;
  }
  return message;
}

function _getResponseErrorMessage(error, type) {
  if (type === "onboarding") {
    if (error.message === "Network Error") {
      axios.put(
        `${API_URL}/integromat/network/log`,
        { error: JSON.stringify(error) },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
    } else {
      axios.put(
        `${API_URL}/integromat/onboarding/log`,
        { error: JSON.stringify(error) },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
    }
  } else {
    if (
      error.message === "Network Error" &&
      localStorage.getItem("accessToken")
    ) {
      axios.put(
        `${API_URL}/integromat/network/log`,
        { error: JSON.stringify(error) },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
    }
  }

  if (error.response && error.response.data) return error.response.data.message;
  if (error.response && error.response.statusText)
    return error.response.statusText;

  return error.message === "Network Error"
    ? "Oops! Network Error. Try again later"
    : error.message;
  // return JSON.stringify(error.response);
}

/**
 * Create instant, which represent response object
 * @param {Object} [data] - custom data
 * @param {Object} [response] - axios response object
 * @param {String} [message] - custom message to display
 */
export class ResponseWrapper {
  constructor(response, data = {}, message) {
    this.data = data;
    this.success = response.data.success;
    this.status = response.status;
    this.statusMessage = _getStatusMessage(this.status);
    this.message = message || null;
  }
}

/**
 * Create instant, which represent error object
 * @param {Object} [error] - axios error object
 * @param {String} [message] - custom message to display
 */
export class ErrorWrapper extends Error {
  constructor(error, message, type) {
    super();
    this.success = error.response ? error.response.data.success : false;
    this.meta = error.response ? error.response.data.meta : false;
    this.code = error.response ? error.response.data.code : false;
    this.status = error.response ? error.response.status : false;
    this.statusMessage = _getStatusMessage(this.status);
    this.message = message || _getResponseErrorMessage(error, type);

    // Automatic login redirection
    if (this.message == "Invalid token") {
      window.localStorage.removeItem("accessToken");
      window.localStorage.removeItem("token");
      $router.push({ path: "/login?expired=true" });
    }
  }
}

/**
 * Uses to clear request data before send it
 * Client shouldn't change entity id
 * @param data
 * @return {{}}
 */
export function clearData(data) {
  const result = {};
  _.forEach(data, (item, propName) => {
    if (_.isArray(item) && item.length) {
      result[propName] = item;
    }
    if (!_.isArray(item) && item && propName !== "id") {
      result[propName] = item;
    }
  });
  return result;
}
/**
 * Uses to clear request data before send it
 * Client shouldn't change entity id
 * @param
 * @returns {string}
 */
const generateUniqueId = require("generate-unique-id");
export function generateEventId() {
  return generateUniqueId();
}
