import Vue from "vue";
import Vuex from "vuex";
import axios from "@/axios";
import axiosRetry from "axios-retry";
import modules from "./modules";
import LocalStorageService from "@/localStorage";
import {
  browserName,
  browserVersion,
  deviceDetect,
} from "mobile-device-detect";
import DeviceDetector from "device-detector-js";

// utils
import $cookies from "@/utils/cookies";

// constants
import { COOKIES } from "@/constants";

const localStorageService = LocalStorageService.getService();

Vue.use(Vuex);

axiosRetry(axios, {
  retries: 1,
  shouldResetTimeout: true,
  retryCondition: () => true,
  retryDelay: (retryCount) => {
    return retryCount * 3000;
  },
});

export default new Vuex.Store({
  state: {
    website: process.env.VUE_APP_WEBSITE_URL,
    errorlog: process.env.VUE_APP_ERROR_LOG_URL,
  },
  mutations: {},
  actions: {
    async getEverflowDetails() {
      if (typeof EF != "undefined") {
        let transaction_id = EF.getAdvertiserTransactionId(1);
        if (EF && transaction_id) {
          return {
            type: "everflow",
            id: transaction_id,
          };
        } else {
          return {};
        }
      }
    },
    async sendErrorLog(state, data) {
      // ? Comment this to test data alerts in local

      // Filter out any misinputs
      if (data.error_log.status == 400) return;

      const error_details =
        data.error_log.status == 500
          ? `Server error -- Log ID: ${data.error_log.logid}`
          : data.error_log.message;

      const cus_id = data?.cus_id ? data.cus_id : "No ID (Unregistered)";
      const email = data?.email ? data.email : "No Email (Unregistered)";

      const currentDate = new Date();

      const eventObject = {
        event: "ONBOARDING_ERROR",
        created_at: currentDate,
        referring_step: data.step,
        customerid: cus_id,
        email: email,
        error_log: error_details,
        environment: process.env.VUE_APP_SERVER,
      };

      if (process.env.VUE_APP_SERVER == "local") {
        eventObject.environment = "STAGING";
      }
      const requestOptions = {
        method: "post",
        url: process.env.VUE_APP_ERROR_LOG_URL,
        data: eventObject,
      };

      if (typeof error_details == "String") {
        eventObject.error_log = {
          id: "clientside_error",
          message: error_details,
        };
      }

      // * Comment this out before deploying-- This is for testing only
      console.log(eventObject);

      try {
        await axios.request(requestOptions);
      } catch (err) {
        console.error(err);
      }
    },
    async getFBQParams() {
      const fbp = await Vue.$cookies.get("_fbp");
      const fbc = await Vue.$cookies.get("_fbc");
      const fbParams = {};
      if (fbp) {
        fbParams.fbp = fbp;
      }
      if (fbc) {
        fbParams.fbc = fbc;
      }
      return fbParams;
    },
    async getVoluumDetails() {
      // Set to store function in case more details are needed/set to a separate property
      let clickid = Vue.$cookies.get("taboolaClickID");
      let ret = {};
      if (clickid) {
        ret.clickID = clickid.taboolaClickID;
      }
      return ret;
    },
    async geDeviceDetails() {
      try {
        let detect = deviceDetect();
        let device = "Desktop";
        if (detect.isMobileOnly != undefined && detect.isMobileOnly === true) {
          device = "Mobile";
        } else if (detect.isTablet != undefined && detect.isTablet === true) {
          device = "Tablet";
        } else if (detect.isMobile != undefined && detect.isMobile === true) {
          device = "Mobile";
        } else if (detect.isSmartTV != undefined && detect.isSmartTV === true) {
          device = "SmartTV";
        } else if (
          detect.isWearable != undefined &&
          detect.isWearable === true
        ) {
          device = "Wearable";
        }

        const deviceDetector = new DeviceDetector();
        const detectedDevice = deviceDetector.parse(navigator.userAgent);

        let hardwareModel = `${detectedDevice.device.brand} ${detectedDevice.device.model}`;

        let campaign = undefined;
        let winona_utms = Vue.$cookies.get("winona_utms") || undefined;

        if (
          winona_utms != undefined &&
          winona_utms.constructor.name === "Object"
        ) {
          let utm_name = winona_utms.utm_name || undefined;
          let utm_source = winona_utms.utm_source || undefined;
          let utm_medium = winona_utms.utm_medium || undefined;
          let utm_campaign = winona_utms.utm_campaign || undefined;
          let utm_term = winona_utms.utm_term || undefined;
          let utm_content = winona_utms.utm_content || undefined;

          if (
            utm_source != undefined ||
            utm_name != undefined ||
            utm_medium != undefined ||
            utm_campaign != undefined ||
            utm_term != undefined ||
            utm_content != undefined
          ) {
            campaign = {
              name: utm_name,
              source: utm_source,
              medium: utm_medium,
              campaign: utm_campaign,
              term: utm_term,
              content: utm_content,
            };
          }
        }

        let firsttouchcampaign = undefined;
        let winona_firsttouch_utms =
          Vue.$cookies.get("winona_firsttouch_utms") || undefined;

        if (
          winona_firsttouch_utms != undefined &&
          winona_firsttouch_utms.constructor.name === "Object"
        ) {
          let utm_name = winona_firsttouch_utms.utm_name || undefined;
          let utm_source = winona_firsttouch_utms.utm_source || undefined;
          let utm_medium = winona_firsttouch_utms.utm_medium || undefined;
          let utm_campaign = winona_firsttouch_utms.utm_campaign || undefined;
          let utm_term = winona_firsttouch_utms.utm_term || undefined;
          let utm_content = winona_firsttouch_utms.utm_content || undefined;

          if (
            utm_source != undefined ||
            utm_name != undefined ||
            utm_medium != undefined ||
            utm_campaign != undefined ||
            utm_term != undefined ||
            utm_content != undefined
          ) {
            firsttouchcampaign = {
              name: utm_name,
              source: utm_source,
              medium: utm_medium,
              campaign: utm_campaign,
              term: utm_term,
              content: utm_content,
            };
          }
        }

        let ret = {
          browser: browserName,
          browserVersion: browserVersion,
          os: detect.osName != undefined ? detect.osName : detect.os,
          os_version: detect.osVersion,
          device: device,
          campaign: campaign,
          firsttouchcampaign: firsttouchcampaign,
        };

        if (device != "Desktop") {
          ret.hardwareModel = hardwareModel;
        }

        return ret;
      } catch (err) {
        console.log(err);
      }
    },

    async getDeviceDetailsForContext() {
      try {
        let detect = deviceDetect();
        let device = "Desktop";
        if (detect.isMobileOnly != undefined && detect.isMobileOnly === true) {
          device = "Mobile";
        } else if (detect.isTablet != undefined && detect.isTablet === true) {
          device = "Tablet";
        } else if (detect.isMobile != undefined && detect.isMobile === true) {
          device = "Mobile";
        } else if (detect.isSmartTV != undefined && detect.isSmartTV === true) {
          device = "SmartTV";
        } else if (
          detect.isWearable != undefined &&
          detect.isWearable === true
        ) {
          device = "Wearable";
        }

        let ret = {
          browserVersion: browserVersion,
          browser: browserName,
          device: device,
          // "Session UTM Source: "Sample",
          // "Session UTM  Campaign: "Sample",
          // "Session UTM Medium: "Sample",
          // "Session UTM Term : "Sample",
          // :Session UTM Content: "Sample",
        };

        let campaign = undefined;
        let winona_utms = Vue.$cookies.get("winona_utms") || undefined;
        let winona_firsttouch_utms =
          Vue.$cookies.get("winona_firsttouch_utms") || undefined;

        // if(winona_firsttouch_utms === undefined && winona_utms != undefined)
        // {
        //   winona_firsttouch_utms = winona_utms
        //   let str_cookie_firsttouch = JSON.stringify(winona_firsttouch_utms)
        //   Vue.$cookies.set("winona_firsttouch_utms", str_cookie_firsttouch)
        // }

        if (winona_utms === undefined || winona_firsttouch_utms === undefined) {
          let nobackendutm = Vue.$cookies.get("nobackendutm") || undefined;
          let nobackendfirsttouchutm =
            Vue.$cookies.get("nobackendfirsttouchutm") || undefined;

          if (
            nobackendutm === undefined ||
            nobackendfirsttouchutm === undefined
          ) {
            let sessionutm = await this.dispatch("getSessionUTM");
            let firsttouch = await this.dispatch("getFirstTouchUTM");

            // if(Object.keys(sessionutm).length > 0 && winona_firsttouch_utms === undefined){
            //   winona_firsttouch_utms = winona_utms
            //   let str_cookie_firsttouch = JSON.stringify(winona_firsttouch_utms)
            //   Vue.$cookies.set("winona_firsttouch_utms", str_cookie_firsttouch)
            // }

            let parsedutm = {};
            if (sessionutm["utm-content"]) {
              parsedutm.utm_content = sessionutm["utm-content"];
            }
            if (sessionutm["utm-campaign"]) {
              parsedutm.utm_campaign = sessionutm["utm-campaign"];
            }
            if (sessionutm["utm-source"]) {
              parsedutm.utm_source = sessionutm["utm-source"];
            }
            if (sessionutm["utm-medium"]) {
              parsedutm.utm_medium = sessionutm["utm-medium"];
            }
            if (sessionutm["utm-term"]) {
              parsedutm.utm_term = sessionutm["utm-term"];
            }

            let parsedftutm = {};
            if (firsttouch["utm-content"]) {
              parsedftutm.utm_content = firsttouch["utm-content"];
            }
            if (firsttouch["utm-campaign"]) {
              parsedftutm.utm_campaign = firsttouch["utm-campaign"];
            }
            if (firsttouch["utm-source"]) {
              parsedftutm.utm_source = firsttouch["utm-source"];
            }
            if (firsttouch["utm-medium"]) {
              parsedftutm.utm_medium = firsttouch["utm-medium"];
            }
            if (firsttouch["utm-term"]) {
              parsedftutm.utm_term = firsttouch["utm-term"];
            }

            if (
              Object.keys(parsedutm).length > 0 ||
              Object.keys(parsedftutm).length > 0
            ) {
              let str_cookie = JSON.stringify(parsedutm);
              Vue.$cookies.set("winona_utms", str_cookie);

              // if(Object.keys(parsedutm).length > 0 && Object.keys(parsedftutm).length === 0){

              //   Vue.$cookies.set("winona_firsttouch_utms", str_cookie)

              // }else{
              let str_cookie_firsttouch = JSON.stringify(parsedftutm);
              Vue.$cookies.set("winona_firsttouch_utms", str_cookie_firsttouch);
              winona_firsttouch_utms = parsedftutm;
              // }
            } else {
              Vue.$cookies.set("nobackendutm", true, "1h");
              Vue.$cookies.set("nobackendfirsttouchutm", true, "1h");

              if (
                winona_firsttouch_utms === undefined &&
                winona_utms != undefined
              ) {
                winona_firsttouch_utms = winona_utms;
                let str_cookie_firsttouch = JSON.stringify(winona_utms);
                Vue.$cookies.set(
                  "winona_firsttouch_utms",
                  str_cookie_firsttouch
                );
              }
            }
          }
        }

        if (
          winona_utms != undefined &&
          winona_utms.constructor.name === "Object"
        ) {
          let utm_name = winona_utms.utm_name || undefined;
          let utm_source = winona_utms.utm_source || undefined;
          let utm_medium = winona_utms.utm_medium || undefined;
          let utm_campaign = winona_utms.utm_campaign || undefined;
          let utm_term = winona_utms.utm_term || undefined;
          let utm_content = winona_utms.utm_content || undefined;
          let campaignId = Number(winona_utms.utm_id) || undefined;

          // Unset campaign ID if NaN
          if (campaignId && isNaN(campaignId)) {
            campaignId = undefined;
          }

          // if(utm_source != undefined || utm_name != undefined|| utm_medium != undefined|| utm_campaign != undefined || utm_term != undefined|| utm_content != undefined)
          // {
          //   campaign = {
          //     name: utm_name,
          //     source: utm_source,
          //     medium: utm_medium,
          //     campaign: utm_campaign,
          //     term: utm_term,
          //     content: utm_content,
          //   }
          // }

          if (utm_source != undefined) {
            ret["sessionUTMSource"] = utm_source;
          }
          if (utm_medium != undefined) {
            ret["sessionUTMMedium"] = utm_medium;
          }
          if (utm_campaign != undefined) {
            ret["sessionUTMCampaign"] = utm_campaign;
          }
          if (utm_term != undefined) {
            ret["sessionUTMTerm"] = utm_term;
          }
          if (utm_content != undefined) {
            ret["sessionUTMContent"] = utm_content;
          }
          if (campaignId != undefined) {
            ret["campaignId"] = campaignId;
          }
        }

        if (
          winona_firsttouch_utms != undefined &&
          winona_firsttouch_utms.constructor.name === "Object"
        ) {
          let utm_name = winona_firsttouch_utms.utm_name || undefined;
          let utm_source = winona_firsttouch_utms.utm_source || undefined;
          let utm_medium = winona_firsttouch_utms.utm_medium || undefined;
          let utm_campaign = winona_firsttouch_utms.utm_campaign || undefined;
          let utm_term = winona_firsttouch_utms.utm_term || undefined;
          let utm_content = winona_firsttouch_utms.utm_content || undefined;

          // if(utm_source != undefined || utm_name != undefined|| utm_medium != undefined|| utm_campaign != undefined || utm_term != undefined|| utm_content != undefined)
          // {
          //   campaign = {
          //     name: utm_name,
          //     source: utm_source,
          //     medium: utm_medium,
          //     campaign: utm_campaign,
          //     term: utm_term,
          //     content: utm_content,
          //   }
          // }

          if (utm_source != undefined) {
            ret["firsttouchUTMSource"] = utm_source;
          }
          if (utm_medium != undefined) {
            ret["firsttouchUTMMedium"] = utm_medium;
          }
          if (utm_campaign != undefined) {
            ret["firsttouchUTMCampaign"] = utm_campaign;
          }
          if (utm_term != undefined) {
            ret["firsttouchUTMTerm"] = utm_term;
          }
          if (utm_content != undefined) {
            ret["firsttouchUTMContent"] = utm_content;
          }
        }

        ret.os = detect.osName != undefined ? detect.osName : detect.os;
        ret.os_version = detect.osVersion;

        const deviceDetector = new DeviceDetector();
        const detectedDevice = deviceDetector.parse(navigator.userAgent);

        let hardwareModel = `${detectedDevice.device.brand} ${detectedDevice.device.model}`;
        if (device != "Desktop") {
          ret.hardwareModel = hardwareModel;
        }

        return ret;
      } catch (err) {
        console.log(err);
      }
    },

    async setUTMS(context, query) {
      let cookie = {};

      if (query.utm_source != undefined) {
        cookie.utm_source = query.utm_source;
      }

      if (query.utm_medium != undefined) {
        cookie.utm_medium = query.utm_medium;
      }

      if (query.utm_campaign != undefined) {
        cookie.utm_campaign = query.utm_campaign;
      }

      if (query.utm_term != undefined) {
        cookie.utm_term = query.utm_term;
      }

      if (query.utm_content != undefined) {
        cookie.utm_content = query.utm_content;
      }

      if (query.campaignId != undefined) {
        cookie.utm_id = query.campaignId;
      }

      if (query.campaignID != undefined) {
        cookie.utm_id = query.campaignID;
      }

      if (
        query[COOKIES.SHOW_PROMO_FORM.KEY] === COOKIES.SHOW_PROMO_FORM.VALUE
      ) {
        $cookies.set(
          COOKIES.SHOW_PROMO_FORM.COOKIE_NAME,
          true,
          COOKIES.SHOW_PROMO_FORM.COOKIE_DURATION
        );
      }

      if (Object.keys(cookie).length > 0) {
        let strCookies = JSON.stringify(cookie);

        Vue.$cookies.set("winona_utms", strCookies, "3d", "/", ".bywinona.com");

        // Check if firsttouch UTMs exist
        let firsttouch = Vue.$cookies.get("winona_firsttouch_utms");
        if (!firsttouch) {
          Vue.$cookies.set(
            "winona_firsttouch_utms",
            strCookies,
            "3d",
            "/",
            ".bywinona.com"
          );
        }
      }
    },

    async getUTMS() {
      let campaign = undefined;
      let winona_utms = Vue.$cookies.get("winona_utms") || undefined;

      if (
        winona_utms != undefined &&
        winona_utms.constructor.name === "Object"
      ) {
        let utm_name = winona_utms.utm_name || undefined;
        let utm_source = winona_utms.utm_source || undefined;
        let utm_medium = winona_utms.utm_medium || undefined;
        let utm_campaign = winona_utms.utm_campaign || undefined;
        let utm_term = winona_utms.utm_term || undefined;
        let utm_content = winona_utms.utm_content || undefined;

        if (
          utm_source != undefined ||
          utm_name != undefined ||
          utm_medium != undefined ||
          utm_campaign != undefined ||
          utm_term != undefined ||
          utm_content != undefined
        ) {
          campaign = {
            "utm-name": utm_name,
            "utm-source": utm_source,
            "utm-medium": utm_medium,
            "utm-campaign": utm_campaign,
            "utm-term": utm_term,
            "utm-content": utm_content,
          };
        }
      }

      return campaign;
    },
    // login to WINONA
    login(context, data) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "post",
          url: "signin",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
        };
        axios(options)
          .then(function (response) {
            if (response?.data?.message && response.data.message !== "success")
              return reject(response.data);
            localStorageService.setToken(response.data.body.idToken);
            return resolve(response.data.body);
          })
          .catch(function (error) {
            if (error?.response?.data?.message)
              return reject(error?.response?.data);
            return reject(error);
          });
      });
    },

    getUser(context, data) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "get",
          url: "user",
          data: data,
          headers: {
            Authorization: `Bearer ${localStorageService.getAccessToken()}`,
          },
        };
        axios(options)
          .then(function (response) {
            // console.log(response.data)
            // if (response?.data?.message && response.data.message !== "success") return reject(response.data)
            localStorageService.setuserID(response.data.body.id);
            return resolve(response.data.body);
          })
          .catch(function (error) {
            if (error?.response?.data?.message)
              return reject(error?.response?.data);
            return reject(error);
          });
      });
    },

    // first step signup for new user
    signup(context, data) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "post",
          url: "signup",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
        };
        axios(options)
          .then(function (response) {
            if (response?.data?.message && response.data.message !== "success")
              return reject(response.data);
            localStorageService.setToken(response.data.body.idToken);
            return resolve(response.data.body);
          })
          .catch(function (error) {
            if (error?.response?.data?.message)
              return reject(error.response.data);
            console.log(error);
            return reject(error);
          });
      });
    },

    // check status for wizard form
    getBoardingStatus(context, data) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "get",
          url: "onboarding",
          data: data,
          headers: {
            Authorization: `Bearer ${localStorageService.getAccessToken()}`,
          },
        };
        axios(options)
          .then(function (response) {
            // console.log(response.data)
            // if (response?.data?.message && response.data.message !== "success") return reject(response.data)
            return resolve(response.data.body);
          })
          .catch(function (error) {
            if (error?.response?.data?.message)
              return reject(error?.response?.data);
            return reject(error);
          });
      });
    },
    // fill up basic information
    // update user
    updateUser(context, data) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "put",
          url: "user",
          data: data,
          headers: {
            Authorization: `Bearer ${localStorageService.getAccessToken()}`,
          },
          validateStatus: (status) => {
            return true; // I'm always returning true, you may want to do it depending on the status received
          },
        };
        axios(options)
          .then(function (response) {
            // console.log(response.data.body)
            if (response?.data?.message && response.data.message !== "success")
              return reject(response.data);
            return resolve(response.data.body);
          })
          .catch(function (error) {
            if (error?.response?.data?.message)
              return reject(error?.response?.data);
            return reject(error);
          });
      });
    },

    // upload avatar
    uploadImage(context, data) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "post",
          url: "user/avatar",
          data: data,
          headers: {
            Authorization: `Bearer ${localStorageService.getAccessToken()}`,
            "Content-Type": "multipart/form-data",
          },
          validateStatus: (status) => {
            return true; // I'm always returning true, you may want to do it depending on the status received
          },
        };
        axios(options)
          .then(function (response) {
            if (response?.data?.message && response.data.message !== "success")
              return reject(response.data);
            return resolve(response.data.body);
          })
          .catch(function (error) {
            if (error?.response?.data?.message)
              return reject(error?.response?.data);
            return reject(error);
          });
      });
    },

    // shipping details
    addShippingDetails(context, data) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "post",
          url: "shipping",
          data: data,
          headers: {
            Authorization: `Bearer ${localStorageService.getAccessToken()}`,
          },
          validateStatus: (status) => {
            return true; // I'm always returning true, you may want to do it depending on the status received
          },
        };
        axios(options)
          .then(function (response) {
            if (response?.data?.message && response.data.message !== "success")
              return reject(response.data);
            return resolve(response.data);
          })
          .catch(function (error) {
            if (error?.response?.data?.message)
              return reject(error?.response?.data);
            console.log(error);
            return reject(error);
          });
      });
    },

    getShippingDetails(context, data) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "get",
          url: `shipping`,
          data: data,
          headers: {
            Authorization: `Bearer ${localStorageService.getAccessToken()}`,
          },
        };
        axios(options)
          .then(function (response) {
            if (response?.data?.message && response.data.message !== "success")
              return reject(response.data);
            return resolve(response.data.body);
          })
          .catch(function (error) {
            if (error?.response?.data?.message)
              return reject(error?.response?.data);
            return reject(error);
          });
      });
    },

    // credit card details
    addCardDetails(context, data) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "post",
          url: "payment",
          data: data,
          headers: {
            Authorization: `Bearer ${localStorageService.getAccessToken()}`,
          },
        };
        axios(options)
          .then(function (response) {
            if (response?.data?.message && response.data.message !== "success")
              return reject(response.data);
            return resolve(response.data);
          })
          .catch(function (error) {
            if (error?.response?.data?.message)
              return reject(error?.response?.data);
            console.log(error);
            return reject(error);
          });
      });
    },

    getCardDetails(context, data) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "get",
          url: "payment",
          data: data,
          headers: {
            Authorization: `Bearer ${localStorageService.getAccessToken()}`,
          },
        };
        axios(options)
          .then(function (response) {
            if (response?.data?.message && response.data.message !== "success")
              return reject(response.data);
            return resolve(response.data);
          })
          .catch(function (error) {
            if (error?.response?.data?.message)
              return reject(error?.response?.data);
            console.log(error);
            return reject(error);
          });
      });
    },

    // end flow when user get boring
    logout() {
      return new Promise((resolve, reject) => {
        axios
          .post(
            "signout",
            {
              /* body */
            },
            {
              headers: {
                Authorization: `Bearer ${localStorageService.getAccessToken()}`,
              },
            }
          )
          .then(function (response) {
            localStorageService.clearToken();
            return resolve(response.data.body);
          })
          .catch(function (error) {
            if (error?.response?.data?.message)
              return reject(error?.response?.data);
            return reject(error);
          });
      });
    },

    //JAMES
    async getConversation(context) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "get",
          url: `conversation`,
          headers: {
            Authorization: `Bearer ${localStorageService.getAccessToken()}`,
          },
        };
        axios(options)
          .then(function (response) {
            if (response?.data?.message && response.data.message !== "success")
              return reject(response.data);
            return resolve(response.data.body);
          })
          .catch(function (error) {
            if (error?.response?.data?.message)
              return reject(error?.response?.data);
            return reject(error);
          });
      });
    },

    async getConversationMember(context, data) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "get",
          url: `conversation/${data.id}/member`,
          headers: {
            Authorization: `Bearer ${localStorageService.getAccessToken()}`,
          },
        };
        axios(options)
          .then(function (response) {
            if (response?.data?.message && response.data.message !== "success")
              return reject(response.data);
            return resolve(response.data.body);
          })
          .catch(function (error) {
            if (error?.response?.data?.message)
              return reject(error?.response?.data);
            return reject(error);
          });
      });
    },

    async getConversationMessage(context, data) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "get",
          url: `conversation/${data.id}/message?nextpage=${data.nextPage}`,
          headers: {
            Authorization: `Bearer ${localStorageService.getAccessToken()}`,
          },
        };
        axios(options)
          .then(function (response) {
            if (response?.data?.message && response.data.message !== "success")
              return reject(response.data);
            return resolve(response.data.body);
          })
          .catch(function (error) {
            if (error?.response?.data?.message)
              return reject(error?.response?.data);
            return reject(error);
          });
      });
    },

    async sendMessage(context, data) {
      return new Promise((resolve, reject) => {
        let conversationid = data.id;
        delete data["id"];
        const options = {
          method: "post",
          url: `conversation/${conversationid}/message`,
          data: data,
          headers: {
            Authorization: `Bearer ${localStorageService.getAccessToken()}`,
          },
        };
        axios(options)
          .then(function (response) {
            if (response?.data?.message && response.data.message !== "success")
              return reject(response.data);
            return resolve(response.data.body);
          })
          .catch(function (error) {
            if (error?.response?.data?.message)
              return reject(error?.response?.data);
            return reject(error);
          });
      });
    },

    //SESSION UTM
    async getSessionUTM() {
      return new Promise((resolve, reject) => {
        const options = {
          method: "get",
          url: `session/utm`,
          headers: {
            Authorization: `Bearer ${localStorageService.getAccessToken()}`,
          },
        };
        axios(options)
          .then(function (response) {
            if (response?.data?.message && response.data.message !== "success")
              return reject(response.data);
            return resolve(response.data.body);
          })
          .catch(function (error) {
            return resolve({});
            // if (error?.response?.data?.message) return reject(error?.response?.data)
            // return reject(error)
          });
      });
    },
    //FIRST TOUCH UTM
    async getFirstTouchUTM() {
      return new Promise((resolve, reject) => {
        const options = {
          method: "get",
          url: `firsttouch/utm`,
          headers: {
            Authorization: `Bearer ${localStorageService.getAccessToken()}`,
          },
        };
        axios(options)
          .then(function (response) {
            if (response?.data?.message && response.data.message !== "success")
              return reject(response.data);
            return resolve(response.data.body);
          })
          .catch(function (error) {
            return resolve({});
            // if (error?.response?.data?.message) return reject(error?.response?.data)
            // return reject(error)
          });
      });
    },
  },
  modules,
});
