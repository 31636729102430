import { NotificationService } from "@/services/notification.service";

export default {
  namespaced: true,
  state: {
    notifications: []
  },
  mutations: {
    SET_NOTIFICATIONS(state, notificationData) {
      state.notifications = notificationData;
    }
  },
  getters: {
    getNotifications(state) {
      return state.notifications;
    }
  },
  actions: {
    getNotifications({ commit }, nextpage) {
      return NotificationService.getNotifications(nextpage)
        .then(({ data: notifications }) => {
          commit('SET_NOTIFICATIONS', notifications.items);
          return notifications;
        })
        .catch(error => error);
    },
    markAsRead({ commit }, id) {
      return NotificationService.markAsRead(id)
        .then(({ data: notifications }) => {
          return notifications
        })
        .catch(error => error);
    },
    markAllAsRead({ commit }) {
      return NotificationService.markAllAsRead()
        .then(({ data: notifications }) => {
          return notifications
        })
        .catch(error => error);
    }
  }
}