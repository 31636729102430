import _ from "lodash";

export function mbToBytes(mb) {
  return _.ceil(mb * 1024 * 1024, 2);
}

export function bytesToMb(bytes) {
  return _.ceil(bytes / 1024 / 1024, 2);
}

export function toUsCurrency(number) {
  return Number(number).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
}

export function toNumber(number) {
  return Number.isNaN(number) || _.isNil(number) ? 0 : Number(number);
}
