import user from "./user";
import notification from "./notification";
import global from "./global";
import onboarding from "./onboarding";
import upsell from "./upsell";
import message from "./message";
import support from "./support";
import socket from "./socket";
import configuration from "./configuration";

export default {
  // auth,
  // step,
  configuration,
  user,
  notification,
  global,
  onboarding,
  upsell,
  message,
  support,
  socket,
};
