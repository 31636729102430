var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-navbar",
    { attrs: { toggleable: "sm", fixed: "top" } },
    [
      _c(
        "b-container",
        { staticClass: "navigator", attrs: { fluid: "" } },
        [
          _c(
            "b-navbar-brand",
            { attrs: { to: "/" } },
            [
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/logo_black.svg"),
                    alt: "",
                  },
                }),
              ],
            ],
            2
          ),
          _c(
            "b-navbar-nav",
            {
              staticClass: "d-none d-sm-none d-md-flex",
              staticStyle: { "padding-left": "40px" },
            },
            _vm._l(_vm.navList, function (list, i) {
              return _c(
                "b-nav-item",
                {
                  key: i + "3",
                  staticClass: "px-3",
                  attrs: {
                    to: list.link,
                    exact: "",
                    "exact-active-class": "active",
                  },
                },
                [
                  _c(
                    "span",
                    { staticClass: "d-flex align-items-center" },
                    [
                      _c("span", { staticStyle: { "line-height": "14px" } }, [
                        _vm._v(_vm._s(list.name)),
                      ]),
                      list.badge
                        ? _c(
                            "b-badge",
                            {
                              staticClass: "ml-2",
                              staticStyle: {
                                background: "#ff8d40",
                                color: "white",
                                "line-height": "11px",
                              },
                              attrs: { pill: "" },
                            },
                            [_vm._v(" " + _vm._s(list.badge) + " ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              )
            }),
            1
          ),
          _c(
            "div",
            { staticClass: "navbar-user ml-center" },
            [
              _c("notification"),
              _c(
                "b-dropdown",
                {
                  class: ["user-dropdown"],
                  attrs: { variant: "link", right: "", "no-caret": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "button-content",
                      fn: function () {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "dropdown-toggle",
                              staticStyle: { padding: "0 !important" },
                              attrs: {
                                role: "button",
                                "data-toggle": "dropdown",
                                "aria-haspopup": "true",
                                "aria-expanded": "false",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "position-relative" },
                                [
                                  _c("b-avatar", {
                                    staticClass: "mr-3",
                                    attrs: {
                                      variant: "primary",
                                      text:
                                        _vm.username && _vm.username.charAt(0),
                                    },
                                  }),
                                  _vm.totalUnreadMessages > 0
                                    ? _c(
                                        "b-badge",
                                        {
                                          staticClass:
                                            "position-absolute d-md-none d-flex justify-content-center align-items-center",
                                          staticStyle: {
                                            background: "#ff8d40",
                                            color: "white",
                                            "line-height": "11px",
                                            right: "2px",
                                          },
                                          attrs: { pill: "" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.totalUnreadMessages) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "h4",
                                {
                                  staticClass:
                                    "mb-0 pr-2 text-uppercase d-none d-sm-none d-md-none d-lg-flex fs-mask",
                                },
                                [_vm._v(" " + _vm._s(_vm.username) + " ")]
                              ),
                              _c("b-icon", {
                                attrs: {
                                  icon: "caret-down-fill",
                                  "font-scale": "0.75",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _vm._l(_vm.navList, function (list, i) {
                    return _c(
                      "b-dropdown-item",
                      {
                        key: i + "3",
                        staticClass: "d-md-none",
                        attrs: {
                          to: list.link,
                          exact: "",
                          "exact-active-class": "active",
                        },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "d-flex align-items-center" },
                          [
                            _c(
                              "span",
                              { staticStyle: { "line-height": "14px" } },
                              [_vm._v(_vm._s(list.name))]
                            ),
                            list.badge
                              ? _c(
                                  "b-badge",
                                  {
                                    staticClass: "ml-2",
                                    staticStyle: {
                                      background: "#ff8d40",
                                      color: "white",
                                      "line-height": "11px",
                                    },
                                    attrs: { pill: "" },
                                  },
                                  [_vm._v(" " + _vm._s(list.badge) + " ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  _c("b-dropdown-divider", { staticClass: "d-md-none" }),
                  _vm._l(_vm.accountList, function (a, i) {
                    return _c(
                      "b-dropdown-item",
                      {
                        key: i + "1",
                        attrs: {
                          to: a.link,
                          exact: "",
                          "exact-active-class": "active",
                        },
                      },
                      [_vm._v(" " + _vm._s(a.name) + " ")]
                    )
                  }),
                  _c("b-dropdown-divider", { staticClass: "d-md-none" }),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: {
                        exact: "",
                        "exact-active-class": "active",
                        to: { name: "insurance-documents" },
                      },
                    },
                    [_vm._v(" Insurance Documents ")]
                  ),
                  _c("b-dropdown-divider"),
                  _c("b-dropdown-item", { on: { click: _vm.logout } }, [
                    _vm._v(" Logout "),
                  ]),
                  _c("HSAReceiptDialog", {
                    ref: "HSAReceiptDialog",
                    attrs: { id: _vm.id, name: _vm.username },
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }