var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-col",
    { staticClass: "cart-recover-password", attrs: { cols: "12" } },
    [
      _c(
        "b-row",
        {
          staticClass: "py-5",
          staticStyle: { height: "100%" },
          attrs: { "align-v": "stretch" },
        },
        [
          _vm.fpScreen == "email"
            ? [
                _c("b-col", { attrs: { cols: "12", "align-self": "start" } }, [
                  _c("h1", { staticClass: "mb-3 heading text-center" }, [
                    _vm._v("Recover Password"),
                  ]),
                  _c("p", { staticClass: "text-center label-text" }, [
                    _vm._v(" Don't worry, it happens to the best of us. "),
                  ]),
                ]),
                _c(
                  "b-col",
                  {
                    staticClass: "mb-4",
                    attrs: { cols: "12", "align-self": "center" },
                  },
                  [
                    _c(
                      "b-alert",
                      { attrs: { show: _vm.alert.show, variant: "warning" } },
                      [_vm._v(_vm._s(_vm.alert.message))]
                    ),
                    _c(
                      "label",
                      { staticClass: "input-label", attrs: { for: "email" } },
                      [_vm._v("Email Address:")]
                    ),
                    _c("b-form-input", {
                      staticClass: "pl-0 input-bg fs-mask",
                      attrs: {
                        id: "email",
                        type: "email",
                        state: _vm.emailState,
                        placeholder: "name@address.com",
                        required: "",
                      },
                      model: {
                        value: _vm.email,
                        callback: function ($$v) {
                          _vm.email = $$v
                        },
                        expression: "email",
                      },
                    }),
                    _c(
                      "b-form-invalid-feedback",
                      { attrs: { id: "input-live-feedback" } },
                      [_vm._v(" The email must have '@email.com' ")]
                    ),
                    _c("div", { staticClass: "text-center mt-6" }, [
                      _c("small", { staticClass: "small-text" }, [
                        _vm._v(" Do you remember your password? "),
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:void(0)" },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("set-screen", "login")
                              },
                            },
                          },
                          [_vm._v("Try logging in")]
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { cols: "12", "align-self": "end" } },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "mb-3",
                        attrs: { variant: "primary", block: "", pill: "" },
                        on: { click: _vm.sendForgotPassword },
                      },
                      [_vm._v("Send")]
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.fpScreen == "sent"
            ? [
                _c("b-col", { attrs: { cols: "12", "align-self": "center" } }, [
                  _c("h1", { staticClass: "text-center mb-3 heading" }, [
                    _vm._v("It's on the way"),
                  ]),
                  _c("p", { staticClass: "text-center label-text" }, [
                    _vm._v(
                      " Please check your inbox and follow the instructions to reset your password. "
                    ),
                  ]),
                  _c(
                    "small",
                    { staticClass: "d-block my-6 text-center small-text" },
                    [
                      _vm._v("Didn't receive the link? "),
                      _c(
                        "a",
                        {
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function ($event) {
                              _vm.fpScreen = "email"
                            },
                          },
                        },
                        [_vm._v("Resend Link")]
                      ),
                    ]
                  ),
                ]),
              ]
            : _vm._e(),
          _vm.fpScreen == "newpassword"
            ? [
                _c("b-col", { attrs: { cols: "12", "align-self": "start" } }, [
                  _c("h1", { staticClass: "text-center mb-3 heading" }, [
                    _vm._v("Recover Password"),
                  ]),
                  _c("p", { staticClass: "text-center label-text" }, [
                    _vm._v("Create a strong password"),
                  ]),
                ]),
                _c(
                  "b-col",
                  { attrs: { cols: "12", "align-self": "center" } },
                  [
                    _c(
                      "b-alert",
                      { attrs: { show: _vm.alert.show, variant: "warning" } },
                      [_vm._v(_vm._s(_vm.alert.message))]
                    ),
                    _c(
                      "div",
                      { staticClass: "mb-4", attrs: { role: "group" } },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "input-label",
                            attrs: { for: "password" },
                          },
                          [_vm._v("New Password:")]
                        ),
                        _c("b-form-input", {
                          staticClass: "pl-0 input-bg fs-mask",
                          attrs: {
                            id: "password",
                            type: "password",
                            state: _vm.passwordState,
                            required: "",
                          },
                          model: {
                            value: _vm.password,
                            callback: function ($$v) {
                              _vm.password = $$v
                            },
                            expression: "password",
                          },
                        }),
                        _c(
                          "b-form-invalid-feedback",
                          { attrs: { id: "input-live-feedback" } },
                          [_vm._v(" Min. 8 characters ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "mb-4", attrs: { role: "group" } },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "input-label",
                            attrs: { for: "cpassword" },
                          },
                          [_vm._v("Confirm Password:")]
                        ),
                        _c("b-form-input", {
                          staticClass: "pl-0 input-bg fs-mask",
                          attrs: {
                            id: "cpassword",
                            type: "password",
                            state: _vm.cpasswordState,
                            required: "",
                          },
                          model: {
                            value: _vm.cpassword,
                            callback: function ($$v) {
                              _vm.cpassword = $$v
                            },
                            expression: "cpassword",
                          },
                        }),
                        _c(
                          "b-form-invalid-feedback",
                          { attrs: { id: "input-live-feedback" } },
                          [_vm._v(" Your password does not match ")]
                        ),
                      ],
                      1
                    ),
                    _c("small", { staticClass: "d-block my-6 text-center" }, [
                      _vm._v("Do you remember your password? "),
                      _c(
                        "a",
                        {
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("set-screen", "login")
                            },
                          },
                        },
                        [_vm._v("Try logging in")]
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { cols: "12", "align-self": "end" } },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "mb-3",
                        attrs: {
                          variant: "primary",
                          size: "lg",
                          block: "",
                          pill: "",
                        },
                        on: { click: _vm.newPassword },
                      },
                      [_vm._v("Change Password")]
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.fpScreen == "passwordChange"
            ? [
                _c("b-col", { attrs: { cols: "12", "align-self": "center" } }, [
                  _c("h1", { staticClass: "text-center mb-3 heading" }, [
                    _vm._v(" Your password has been changed "),
                  ]),
                  _c("p", { staticClass: "text-center label-text" }, [
                    _vm._v(
                      " You can now use your new password to log in to your account. "
                    ),
                  ]),
                ]),
                _c(
                  "b-col",
                  { attrs: { cols: "12", "align-self": "end" } },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "mb-3",
                        attrs: { variant: "primary", block: "", pill: "" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("set-screen", "login")
                          },
                        },
                      },
                      [_vm._v("Login")]
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }