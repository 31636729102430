<template>
  <div class="notification-box" ref="notification">
    <v-icon
      :class="[notify ? 'active' : '']"
      :variant="'dark'"
      name="bell"
      size="25"
      v-b-toggle.sidebar-backdrop
    ></v-icon>
    <b-sidebar
      @change="clearNotifications"
      id="sidebar-backdrop"
      backdrop-variant="dark"
      bg-variant="dark"
      backdrop
      right
      no-header
      sidebar-class="side-notification"
    >
      <div class="notification">
        <div class="notification-close">
          <b-icon
            icon="x-circle"
            variant="secondary"
            v-b-toggle.sidebar-backdrop
            style="cursor: pointer"
          ></b-icon>
        </div>
        <div class="notification-header">
          <h2 class="mb-0 heading">Notifications</h2>
        </div>
        <!-- <div class="notification-header">Notifications <a @click.native="markAllAsRead()" href="#">Mark All as Read</a></div> -->
        <ul class="notification-body" v-on:scroll.passive="notificationScroll">
          <li
            v-for="(notification, i) in notifications"
            :key="i"
            :class="[
              'notification-content',
              notification.timestampread ? '' : 'active',
            ]"
          >
            <a :class="['notification-content']">
              <!-- <b-avatar :src="notification.profileUrl" size="50px"></b-avatar> -->
              <div class="notification-text">
                <h4 class="mb-1">{{ notification.title }}</h4>
                <p class="mb-2 small">
                  {{ notification.description }}
                </p>
                <b-row>
                  <b-col cols="6">
                    <small>{{
                      moment
                        .unix(notification.timestampcreated)
                        .format("MMMM DD, YYYY")
                    }}</small>
                  </b-col>
                  <b-col cols="6" class="text-right">
                    <b-icon
                      v-if="!notification.timestampread"
                      icon="trash"
                      variant="secondary"
                      v-on:click="markAsRead(notification.id)"
                    ></b-icon>
                    <!-- <span :class="['next-arrow', {'white': n % 2 === 0 }]"></span> -->
                  </b-col>
                </b-row>
              </div>
            </a>
          </li>
        </ul>
        <!-- <div class="notification-footer">View all Notifications</div> -->
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import gql from "graphql-tag";

import { mapGetters } from "vuex";
import { NotificationService } from "@/services/notification.service";

export default {
  name: "notification",
  data() {
    return {
      notify: false,
      show: false,
      ping: true,
      unread: 0,
      nextPage: null,
    };
  },
  watch: {
    notifications: {
      handler(notifications) {
        this.unread = 0;
        for (let i = 0; i < notifications.length; i++) {
          let notification = notifications[i];
          if (!notification.timestampread) {
            this.unread += 1;
          }
        }
        if (this.unread > 0) {
          this.notify = true;
        } else {
          this.notify = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    initApollo() {
      this.$apollo.addSmartSubscription("notification", {
        query: gql`
          subscription onNotificationActivity($userid: String!) {
            onNotificationActivity(userid: $userid) {
              id
              action
              code
              description
              subject
              subjectid
              timestampcreated
              timestampread
              title
            }
          }
        `,

        variables() {
          return {
            userid: localStorage.getItem("userid"),
          };
        },
        result(data) {
          this.$store
            .dispatch("notification/getNotifications")
            .then((res) => {});
        },
        skip() {
          return false;
        },
        error(err) {
          console.error("err", err);
        },
      });
    },
    toggle() {
      this.show = !this.show;
    },
    close(e) {
      let element =
        !this.$el.contains(e.target) &&
        !this.$refs.notification.contains(e.target);
      if (element) this.show = false;
    },
    // this is for demo only
    randomClass(value) {
      let minVal = 0,
        maxVal = value.length,
        chooseClass = Math.floor(Math.random() * (maxVal - minVal) + minVal);
      return value[chooseClass];
    },
    markAsRead(id) {
      this.$store
        .dispatch("notification/markAsRead", id)
        .then(({ data }) => {
          this.unread -= 1;
          this.$store
            .dispatch("notification/getNotifications")
            .then((res) => res);
        })
        .catch((error) => error);
    },
    markAllAsRead() {
      this.$store
        .dispatch("notification/markAllAsRead")
        .then(({ data }) => {
          this.unread = 0;
          this.$store
            .dispatch("notification/getNotifications")
            .then((res) => res);
        })
        .catch((error) => error);
    },
    async notificationScroll(e) {
      if (e.target.scrollTop == e.target.scrollHeight - e.target.clientHeight) {
        if (this.nextPage) {
          const { data: notifications } =
            await NotificationService.getNotifications(this.nextPage);
          for (let i = 0; i < notifications.items.length; i++) {
            let notification = notifications.items[i];
            this.notifications.push(notification);
            if (!notification.timestampread) {
              this.unread += 1;
            }
          }

          if (notifications.nextpage) {
            this.nextPage = notifications.nextpage;
          } else {
            this.nextPage = null;
          }
        }
      }
    },
    clearNotifications(showSidebar = false) {
      if (!showSidebar) {
        this.markAllAsRead();
      }
      this.notify = false;
    },
  },
  computed: {
    ...mapGetters({
      notifications: "notification/getNotifications",
    }),
  },
  mounted() {
    this.initApollo();
    this.nextPage = null;
    this.$store.dispatch("notification/getNotifications").then((res) => {
      for (let i = 0; i < this.notifications.length; i++) {
        let notification = this.notifications[i];
        if (!notification.timestampread) {
          this.unread += 1;
        }
      }
      this.nextPage = res.nextpage;
    });
    // to close the notification outside
    document.addEventListener("click", this.close);
  },
  beforeDestroy() {
    // for reduce memory consumption we need to remove it after you close the notification
    document.removeEventListener("click", this.close);
  },
};
</script>

<style scoped>
/deep/.bg-dark {
  color: #cdfcb1 !important;
}

@media (min-width: 768px) {
  /deep/.b-sidebar {
    width: 500px;
  }
}
</style>

<style lang="scss" scoped>
$class: ".notification";
$gutter: 2rem;

@media (min-width: 768px) {
  $gutter: 3.5rem;

  #{$class}-header {
    padding-left: $gutter !important;
    padding-right: $gutter !important;
  }

  #{$class}-body > li a {
    margin-left: $gutter !important;
    margin-right: $gutter !important;
  }
}

#{$class}-box {
  position: relative;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  user-select: none;

  @include media-breakpoint-up(xxl) {
    top: 5px;
    width: 30px * $scale-font;
    margin: 5px * $scale-font;
    margin-right: 1rem;
  }

  .is-bell {
    margin: 0;
    display: flex;
  }

  > .icon-holder {
    position: relative;
    cursor: pointer;

    &.active {
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 5px;
        width: 10px;
        height: 10px;
        background-color: $tertiary;
        border-radius: 10px;
      }
    }

    &.support {
      &:after {
        background-color: $secondary;
      }
    }
  }

  #{$class}-close {
    display: flex;
    justify-content: flex-end;
    padding: $gutter;
  }

  #{$class} {
    height: 100vh;

    #{$class}-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: $gutter / 2 $gutter;
    }

    #{$class}-body {
      max-height: calc(100vh - 198px);
      overflow: auto;
      overflow-x: hidden;
      padding: 0;
      margin: 0;

      > li {
        &.active {
          background-color: darken($primary, 5%);
        }

        &:first-child {
          a {
            border-top: 0.5px solid $secondary;
          }
        }

        a {
          padding: $gutter / 2 0;
          margin: 0 $gutter;
          color: inherit;
          cursor: pointer;
          border-bottom: 0.5px solid $secondary;
          text-decoration: none;
        }

        #{$class}-content {
          display: flex;
          align-items: center;

          #{$class}-text {
            flex: 1;
            min-width: 0; // fix text-truncate
          }

          .b-avatar {
            flex: 50px 0 0;
            margin-right: 15px;
          }
        }
      }
    }

    #{$class}-footer {
      background-color: #e8f0f7;
      text-align: center;
      padding: 10px;
    }
  }
}

// #{$class}-box {
//   @include media-breakpoint-up(xxl) {
//     top: -13px;
//     transform: scale(1.5);
//     margin-right: 10px;
//   }
// }

// .notification-box > .icon-holder.active:after {
//   @include media-breakpoint-up(xxl) {
//     top: 11px;
//   }
// }
</style>
