import { ChatService } from "@/services/chat.service.js";

export default {
  namespaced: true,
  state: {
    unreadMessagesCount: 0,
    unreadAdminMessagesCount: 0,
  },
  mutations: {
    SET_UNREAD_COUNT(state, payload) {
      state.unreadMessagesCount = payload;
    },
    SET_UNREAD_ADMIN_MESSAGE_COUNT(state, payload) {
      state.unreadAdminMessagesCount = payload;
    },
    INCREMENT_DOCTOR_MESSAGES_COUNT(state) {
      state.unreadMessagesCount = Number(state.unreadMessagesCount) + 1;
    },
    INCREMENT_ADMIN_MESSAGES_COUNT(state) {
      state.unreadAdminMessagesCount =
        Number(state.unreadAdminMessagesCount) + 1;
    },
  },
  actions: {
    async getUnreadMessageCount({ commit, rootState }) {
      try {
        if (rootState?.user?.currentUser?.id) {
          const { data } = await ChatService.getUnreadMessageCount(
            rootState?.user?.currentUser?.id
          );
          commit("SET_UNREAD_COUNT", data);
          return data;
        }
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    async getAdminUnreadMessageCount({ commit, rootState }) {
      try {
        const { data } = await ChatService.getAdminUnreadMessageCount(
          rootState?.user?.currentUser?.id
        );
        commit("SET_UNREAD_ADMIN_MESSAGE_COUNT", data.count);
        return data;
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    incrementDoctorMessagesCount({ commit }) {
      commit("INCREMENT_DOCTOR_MESSAGES_COUNT");
    },
    incrementAdminMessagesCount({ commit }) {
      commit("INCREMENT_ADMIN_MESSAGES_COUNT");
    },
    resetDoctorMessagesCount({ commit }) {
      commit("SET_UNREAD_COUNT", 0);
    },
    resetAdminMessagesCount({ commit }) {
      commit("SET_UNREAD_ADMIN_MESSAGE_COUNT", 0);
    },
  },
};
