var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-col",
    { staticClass: "cart-billing" },
    [
      _c(
        "b-row",
        { staticStyle: { height: "100%" }, attrs: { "align-v": "stretch" } },
        [
          _c(
            "b-col",
            { attrs: { cols: "12", "align-self": "start" } },
            [
              _c(
                "h3",
                {
                  staticClass: "bold-font text-right mt-3",
                  staticStyle: { "letter-spacing": "0.16em" },
                },
                [_vm._v(" CHECKOUT ")]
              ),
              _c("cartIcon", { staticClass: "mt-5", attrs: { number: "4" } }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", "align-self": "center" } },
            [
              _c("h2", { staticClass: "heading mb-1 text-center" }, [
                _vm._v("You’re almost there"),
              ]),
              _c("p", { staticClass: "text-center" }, [
                _vm._v(
                  " Add payment method and we’re ready to ship your treatment. "
                ),
              ]),
              _c(
                "b-alert",
                { attrs: { show: _vm.alert.show, variant: _vm.alert.type } },
                [_vm._v(_vm._s(_vm.alert.message))]
              ),
              _vm._l(_vm.productList, function (item, index) {
                return _c("div", { key: index, staticClass: "product" }, [
                  _c("div", { staticClass: "product-img" }, [
                    _c("img", { attrs: { src: item.image, alt: "" } }),
                  ]),
                  _c("div", { staticClass: "product-content" }, [
                    _c("h3", { staticClass: "heading mb-0" }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                    _c("h5", {}, [
                      _vm._v(
                        " " +
                          _vm._s(item.dosage) +
                          " — $" +
                          _vm._s(item.selectedPricing.price) +
                          " "
                      ),
                    ]),
                    _c("p", { staticClass: "mb-0" }, [
                      _vm._v(
                        " $" +
                          _vm._s(item.selectedPricing.price * item.quantity) +
                          " "
                      ),
                    ]),
                  ]),
                ])
              }),
              _c(
                "b-list-group",
                { staticClass: "product-amount", attrs: { flush: "" } },
                [
                  _c(
                    "b-list-group-item",
                    { staticClass: "d-flex justify-content-between" },
                    [
                      _vm._v(" Order Sub-total "),
                      _c("span", [_vm._v("$" + _vm._s(_vm.subtotal))]),
                    ]
                  ),
                  _c(
                    "b-list-group-item",
                    { staticClass: "d-flex justify-content-between" },
                    [
                      _vm._v(" Tax "),
                      _c("span", [_vm._v("$" + _vm._s(_vm.taxamount))]),
                    ]
                  ),
                  _c(
                    "b-list-group-item",
                    { staticClass: "d-flex justify-content-between" },
                    [
                      _c("b", { staticClass: "bold-font" }, [
                        _vm._v("Grand Total"),
                      ]),
                      _c("span", [_vm._v("$" + _vm._s(_vm.totalamount))]),
                    ]
                  ),
                ],
                1
              ),
              _c("hr"),
              _c(
                "div",
                [
                  _c("span", { staticClass: "bold-font mb-3" }, [
                    _vm._v("Pay with"),
                  ]),
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { cols: "12", lg: "12" } }, [
                        _c(
                          "div",
                          { staticClass: "mb-3", attrs: { role: "group" } },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "input-label",
                                attrs: { for: "cardNumber" },
                              },
                              [_vm._v("Credit or Debit card*:")]
                            ),
                            _c("div", { ref: "card" }),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { id: "sameAsShippingAddress", name: "bill" },
                      model: {
                        value: _vm.notSame,
                        callback: function ($$v) {
                          _vm.notSame = $$v
                        },
                        expression: "notSame",
                      },
                    },
                    [
                      _c("span", { staticClass: "d-block mt-2" }, [
                        _vm._v(
                          "My billing information is the same as my shipping information"
                        ),
                      ]),
                    ]
                  ),
                  !_vm.notSame
                    ? [
                        _c("p", { staticClass: "mt-6 mb-4 bold-font" }, [
                          _vm._v("Billing Address"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "mb-3", attrs: { role: "group" } },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "input-label",
                                attrs: { for: "billingAddress1" },
                              },
                              [_vm._v("Street Address:")]
                            ),
                            _c("b-form-input", {
                              staticClass: "pl-0 fs-mask",
                              attrs: { id: "billingAddress1", type: "text" },
                              model: {
                                value: _vm.billingAddress1,
                                callback: function ($$v) {
                                  _vm.billingAddress1 = $$v
                                },
                                expression: "billingAddress1",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "mb-3", attrs: { role: "group" } },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "input-label",
                                attrs: { for: "billingAddress2" },
                              },
                              [_vm._v("Apartment/Suite:")]
                            ),
                            _c("b-form-input", {
                              staticClass: "pl-0 fs-mask",
                              attrs: { id: "billingAddress2", type: "text" },
                              model: {
                                value: _vm.billingAddress2,
                                callback: function ($$v) {
                                  _vm.billingAddress2 = $$v
                                },
                                expression: "billingAddress2",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "mb-3", attrs: { role: "group" } },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "input-label",
                                attrs: { for: "billingState" },
                              },
                              [_vm._v("State:")]
                            ),
                            _c("v-select", {
                              staticClass: "cart-state fs-mask",
                              attrs: {
                                "append-to-body": "",
                                "calculate-position": _vm.withPopper,
                                label: "text",
                                options: _vm.billingStateoptions,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "open-indicator",
                                    fn: function ({ attributes }) {
                                      return [
                                        _c(
                                          "b-icon",
                                          _vm._b(
                                            { attrs: { icon: "chevron-down" } },
                                            "b-icon",
                                            attributes,
                                            false
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                607575044
                              ),
                              model: {
                                value: _vm.billingState,
                                callback: function ($$v) {
                                  _vm.billingState = $$v
                                },
                                expression: "billingState",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                              _c(
                                "div",
                                {
                                  staticClass: "mb-3",
                                  attrs: { role: "group" },
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "input-label",
                                      attrs: { for: "billingCity" },
                                    },
                                    [_vm._v("City:")]
                                  ),
                                  _c("b-form-input", {
                                    staticClass: "pl-0 fs-mask",
                                    attrs: { id: "billingCity", type: "text" },
                                    model: {
                                      value: _vm.billingCity,
                                      callback: function ($$v) {
                                        _vm.billingCity = $$v
                                      },
                                      expression: "billingCity",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                              _c(
                                "div",
                                {
                                  staticClass: "mb-3",
                                  attrs: { role: "group" },
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "input-label",
                                      attrs: { for: "billingZipCode" },
                                    },
                                    [_vm._v("Zip Code:")]
                                  ),
                                  _c("b-form-input", {
                                    staticClass: "pl-0 fs-mask",
                                    attrs: {
                                      id: "billingZipCode",
                                      type: "text",
                                    },
                                    model: {
                                      value: _vm.billingZipCode,
                                      callback: function ($$v) {
                                        _vm.billingZipCode = $$v
                                      },
                                      expression: "billingZipCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "12" } },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "input-label",
                                    attrs: { for: "billingPhone" },
                                  },
                                  [_vm._v("Phone No.")]
                                ),
                                _c("b-form-input", {
                                  staticClass: "pl-0 fs-mask",
                                  attrs: {
                                    id: "billingPhone",
                                    type: "text",
                                    formatter: _vm.formatPhoneNumber,
                                  },
                                  model: {
                                    value: _vm.billingPhone,
                                    callback: function ($$v) {
                                      _vm.billingPhone = $$v
                                    },
                                    expression: "billingPhone",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            2
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", "align-self": "end" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "mt-3 mb-3",
                  attrs: { pill: "", block: "", variant: "link" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("set-screen", "shipping")
                    },
                  },
                },
                [_vm._v("Back")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "mt-3 mb-3",
                  attrs: {
                    disabled: _vm.dataloading,
                    pill: "",
                    block: "",
                    variant: "primary",
                  },
                  on: { click: _vm.proceedCheckout },
                },
                [_vm._v(_vm._s(_vm.buttonText))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }