<template>
  <b-col cols="12">
    <b-row style="height: 100%" align-v="stretch" class="py-6">
      <b-col cols="12" align-self="start">
        <h1 class="mb-2 heading text-center">Hello, beautiful!</h1>
        <p class="label-text text-center">Please log into your account</p>
      </b-col>
      <b-col cols="12" align-self="center">
        <b-alert :show="alert.show" variant="warning">{{
          alert.message
        }}</b-alert>
        <div role="group" class="mb-4">
          <label for="email" class="input-label">Email Address:</label>
          <b-form-input
            id="email"
            class="pl-0 fs-mask"
            type="email"
            v-model="email"
            :state="emailState"
            autocomplete="off"
            required
          ></b-form-input>
          <!-- This will only be shown if the preceding input has an invalid state -->
          <b-form-invalid-feedback id="input-live-feedback">
            The email must have '@email.com'
          </b-form-invalid-feedback>
        </div>
        <div role="group" class="mb-4">
          <div class="row">
            <label for="password" class="col input-label">Password:</label>
            <!-- Help text -->
            <!-- <label class="col-auto">
              
              <a href="javascript:void(0)"
                tabindex="-1"
                @click="$emit('set-screen', 'forgotpassword')"
                class="form-text text-primary"
                >Forgot password?</a
              >
            </label> -->
          </div>
          <b-form-input
            id="password"
            class="pl-0 fs-mask"
            v-model="password"
            type="password"
            :state="passwordState"
            autocomplete="off"
            required
          ></b-form-input>
          <!-- This will only be shown if the preceding input has an invalid state -->
          <b-form-invalid-feedback id="input-live-feedback">
            Min. 8 characters
            <!-- Min. 8 characters with at least one capital letter, a number and a
            special character -->
          </b-form-invalid-feedback>
        </div>
        <div class="text-center mt-6">
          <small class="small-text">
            Don't have an account yet?
            <a
              href="javascript:void(0)"
              @click="$emit('set-screen', 'register')"
              >Register</a
            >
          </small>
        </div>
      </b-col>
      <b-col align-self="end">
        <b-button
          :disabled="loginDisabled"
          block
          pill
          variant="primary"
          @click="login"
        >
          {{ buttonText }}
        </b-button>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import mixins from "@/components/form/mixins";
import { AuthService } from "@/services/auth.service";

export default {
  mixins: [mixins],
  props: {
    redirect: {
      type: Boolean,
      default: false,
    },
    redirectURL: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      alert: {
        show: false,
        message: "",
      },
      email: "",
      password: "",
      loginDisabled: false,
    };
  },
  computed: {
    buttonText() {
      return !this.loginDisabled ? "Log In" : "Logging in";
    },
  },
  methods: {
    async login() {
      this.alert.show = false;
      this.loginDisabled = true;
      try {
        const { data } = await AuthService.login({
          email: this.email,
          password: this.password,
        });
        FS.identify(data.userid);

        // SEGMENT
        window.analytics.identify(data.userid, {
          customerId: data.userid,
        });
        window.analytics.track(
          "Login",
          {
            customerId: data.userid,
            ...(await this.$store.dispatch("getDeviceDetailsForContext")),
          },
          {
            context: await this.$store.dispatch("geDeviceDetails"),
          }
        );

        window.localStorage.setItem("cartLogin", 1);
        if (this.redirect) {
          let params = {
            event: "redirect",
            url: this.redirectURL,
            token: data.onetimetoken,
          };
          if (window.parent) {
            window.parent.postMessage(params, process.env.VUE_APP_WEBSITE_URL);
          }
        } else {
          window.location.reload();
        }
      } catch (err) {
        this.loginDisabled = false;
        if (err.message) {
          this.alert.message = err.message;
          this.alert.show = true;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  font-size: 11px !important;
  color: #19191c;
  margin-bottom: 0;

  @include media-breakpoint-up(xxl) {
    font-size: 11px * $scale-font !important;
  }
}
</style>
