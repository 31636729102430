import { UpsellService } from "@/services/upsell.service";

export default {
  namespaced: true,
  state: {
    upsellList: [],
    availableDashboardUpsell: [],
  },
  mutations: {
    SET_UPSELL_LIST(state, upsellList) {
      state.upsellList = upsellList;
    },
    SET_AVAILABLE_UPSELL_DASHBOARD_LIST(state, upsellList) {
      state.availableDashboardUpsell = upsellList;
    },
  },
  actions: {
    getUpsellList({ commit }) {
      return UpsellService.getProductList()
        .then(({ data }) => {
          commit("SET_UPSELL_LIST", data);
        })
        .catch((error) => error);
    },
    acceptUpsell(
      { commit },
      { upsellId, productId, pricingId, triggeredfrom, couponId }
    ) {
      return UpsellService.acceptUpsellProduct(upsellId, {
        triggeredfrom: triggeredfrom,
        productid: productId,
        pricingid: pricingId,
        couponid: couponId,
      })
        .then(() => {
          return { success: true };
        })
        .catch(() => ({ success: false }));
    },
    denyUpsell({ commit }, { upsellId }) {
      return UpsellService.denyUpsellProduct(upsellId)
        .then(() => {
          return { success: true };
        })
        .catch((error) => ({ success: false }));
    },
    addUpsell({ commit }, { product }) {
      return UpsellService.addUpsellToPatient(product)
        .then(() => {
          return { success: true };
        })
        .catch((error) => ({ success: false }));
    },
    fetchAvailableDashboardUpsell({ commit }) {
      return UpsellService.getDashboardUpsellList()
        .then(({ data }) => {
          commit("SET_AVAILABLE_UPSELL_DASHBOARD_LIST", data);
        })
        .catch((error) => error);
    },
  },
};
