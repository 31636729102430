var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-col",
    { staticClass: "cart-account", attrs: { cols: "12" } },
    [
      _c(
        "b-row",
        { staticStyle: { height: "100%" }, attrs: { "align-v": "stretch" } },
        [
          _c(
            "b-col",
            {
              staticClass: "mt-6",
              attrs: { cols: "12", "align-self": "start" },
            },
            [
              _c("h1", { staticClass: "heading h1 color-inherit" }, [
                _vm._v("Hello, " + _vm._s(_vm.firstname)),
              ]),
            ]
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", "align-self": "start" } },
            [
              _c(
                "b-row",
                [
                  _c("b-col", {
                    attrs: { cols: "7", sm: "9", md: "10", xl: "10" },
                  }),
                  _c(
                    "b-col",
                    { attrs: { cols: "5", sm: "3", md: "2", xl: "2" } },
                    [
                      _c("ul", { staticClass: "list-unstyled" }, [
                        _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "color-inherit",
                              attrs: { href: "javascript:void(0);" },
                              on: {
                                click: function ($event) {
                                  return _vm.redirectTo(
                                    "app",
                                    "account-settings"
                                  )
                                },
                              },
                            },
                            [_c("h2", [_vm._v("Profile")])]
                          ),
                        ]),
                        _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "color-inherit",
                              attrs: { href: "javascript:void(0);" },
                              on: {
                                click: function ($event) {
                                  return _vm.redirectTo("app", "treatment")
                                },
                              },
                            },
                            [_c("h2", [_vm._v("Subscriptions")])]
                          ),
                        ]),
                        _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "color-inherit",
                              attrs: { href: "javascript:void(0);" },
                              on: {
                                click: function ($event) {
                                  return _vm.redirectTo("app", "message")
                                },
                              },
                            },
                            [_c("h2", [_vm._v("Messages")])]
                          ),
                        ]),
                        _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "color-inherit",
                              attrs: { href: "javascript:void(0);" },
                              on: {
                                click: function ($event) {
                                  return _vm.redirectTo("website", "support")
                                },
                              },
                            },
                            [_c("h2", [_vm._v("Support")])]
                          ),
                        ]),
                        _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "color-inherit",
                              attrs: { href: "javascript:void(0);" },
                              on: {
                                click: function ($event) {
                                  return _vm.redirectTo("website", "contact")
                                },
                              },
                            },
                            [_c("h2", [_vm._v("Contact Us")])]
                          ),
                        ]),
                        _c("li", { staticClass: "mt-6" }, [
                          _c(
                            "a",
                            {
                              staticClass: "color-inherit",
                              attrs: { href: "javascript:void(0);" },
                              on: { click: _vm.logOut },
                            },
                            [_c("h2", [_vm._v("Log Out")])]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }