import { BaseService } from "./base.service";
import { ErrorWrapper, ResponseWrapper } from "./util";

export class PatientService extends BaseService {
  static async getPatients(nextpage) {
    try {
      const response = await this.request({ auth: true }).get(
        `patient?nextpage=${nextpage}`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getPatient(patientId) {
    try {
      const response = await this.request({ auth: true }).get(
        `patient/${patientId}`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getIncomingPatients(nextpage) {
    try {
      const response = await this.request({ auth: true }).get(
        `patient/incoming?nextpage=${nextpage}`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async acceptPatient(patientId) {
    try {
      const response = await this.request({ auth: true }).put(
        `patient/${patientId}/accept`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getVerification() {
    try {
      const response = await this.request({ auth: true }).get(
        `patient/verification`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getHealthQuestions() {
    try {
      const response = await this.request({ auth: true }).get(
        "/patient/healthquestion"
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async saveHealthQuestions(data) {
    try {
      const response = await this.request({ auth: true }).put(
        "/patient/healthquestion",
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getHSAReceipt(id, year = null) {
    let url;
    if (year) {
      url = `/patient/${id}/hsareceipt?year=${year}`;
    } else {
      url = `/patient/${id}/hsareceipt`;
    }
    try {
      const response = await this.request({ auth: true }).get(url);
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getFSAReceipt(id, year) {
    try {
      let url = year ? `/fsaReceipt/${id}/${year}` : `/fsaReceipt/${id}`;
      const response = await this.request({ auth: true }, "v2").get(url);
      return response;
      // return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getNDCForm(id, year) {
    try {
      let url = year ? `/ndc/${id}/${year}` : `/ndc/${id}`;
      const response = await this.request({ auth: true }, "v2").get(url);
      return response;
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
