var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("w-form", {
    staticClass: "mt-5",
    attrs: {
      name: "login",
      title: "Hello, beautiful!",
      "sub-title": "Please log into your account.",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }