/**
 * HTTP request layer
 * if auth is required return patched axios instance(with access token in headers)
 * else return clear axios instance
 */

import axios from "axios";
import axiosRetry from "axios-retry";

import { AuthService } from "@/services/auth.service";
import VueCookies from "vue-cookies";
export class Http {
  constructor(status, version) {
    this.noRetry = status && status.noRetry ? status.noRetry : false;
    this.isAuth = status && status.auth ? status.auth : false;
    this.trackFB = status && status.trackFB ? status.trackFB : false;
    this.authKey = status && status.authKey ? status.authKey : false;

    if (version === "v1") {
      this.instance = axios.create({
        baseURL: process.env.VUE_APP_API_URL_V1,
      });
    } else {
      if (process.env.VUE_APP_MESSAGING_VERSION === "v2") {
        if (version === "v3") {
          this.instance = axios.create({
            baseURL: process.env.VUE_APP_WEBSOCKET_URL,
          });
        } else {
          this.instance = axios.create({
            baseURL:
              version === "v2"
                ? process.env.VUE_APP_API_URL_V2
                : process.env.VUE_APP_API_URL,
          });
        }
      } else {
        this.instance = axios.create({
          baseURL:
            version === "v2"
              ? process.env.VUE_APP_API_URL_V2
              : process.env.VUE_APP_API_URL,
        });
      }
    }

    NProgress.start();
    return this.init();
  }

  init() {
    axiosRetry(this.instance, {
      retries: 1,
      shouldResetTimeout: true,
      retryCondition: () => true,
      retryDelay: (retryCount) => {
        return retryCount * 3000;
      },
    });

    if (this.isAuth) {
      let winonaCookieId = window.localStorage.getItem("winona-cookie-id");
      this.instance.interceptors.request.use(
        (request) => {
          request.headers.authorization = AuthService.getBearer();
          if (winonaCookieId)
            request.headers["winona-cookie-id"] = winonaCookieId;

          if (this.trackFB) {
            let fbp = VueCookies.get("_fbp") || null;
            let fbc = VueCookies.get("_fbc") || null;
            if (fbp) {
              request.headers.fbp = fbp;
            }
            if (fbc) {
              request.headers.fbc = fbc;
            }
          }

          if (this.authKey) {
            request.headers["winona-api-key"] = process.env.VUE_APP_API_KEY;
          }

          if (this.noRetry) {
            request["axios-retry"] = {
              retries: 0,
            };
          }

          NProgress.start();
          return request;
        },
        (error) => {
          NProgress.done();
          return Promise.reject(error);
        }
      );

      this.instance.interceptors.response.use(
        (response) => {
          NProgress.done();
          return response;
        },
        async (error) => {
          const originalRequest = error.config;
          if (
            error.response &&
            error.response.status === 401 &&
            (error.response.data.message === "The incoming token has expired" ||
              error.response.data.message === "Expired token") &&
            !originalRequest._retry
          ) {
            originalRequest._retry = true;
            try {
              await AuthService.refreshTokens();
              return this.instance(originalRequest);
            } catch (error) {
              if (error.message.includes("401")) {
                return this.instance(originalRequest);
              }
              return Promise.reject(error);
            }
          }
          NProgress.done();
          return Promise.reject(error);
        }
      );
    } else {
      NProgress.done();
    }
    return this.instance;
  }
}
