var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    {
      class: [
        "pageloader",
        "type-" + _vm.loaderType,
        _vm.darkBg ? "is-dark" : "",
        _vm.lightBg ? "is-light" : "",
        _vm.neutralBg ? "is-neutral" : "",
      ],
      attrs: { fluid: "" },
    },
    [
      _c(
        "b-row",
        { staticClass: "height-fluid" },
        [
          _vm.loaderType != 6
            ? _c(
                "b-col",
                { attrs: { cols: "2", md: "1" } },
                [
                  _c(
                    "b-row",
                    {
                      staticClass: "pagenav",
                      staticStyle: { height: "100%" },
                      attrs: { "align-v": "stretch", "align-h": "center" },
                    },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "logo",
                          attrs: {
                            cols: "12",
                            sm: "3",
                            md: "12",
                            "align-self": "start",
                          },
                        },
                        [
                          _vm.darkBg
                            ? _c("img", {
                                attrs: {
                                  src: require("@/assets/images/dark-icon-logo.svg"),
                                  width: "40",
                                },
                              })
                            : _c("img", {
                                attrs: {
                                  src: require("@/assets/images/icon-logo.svg"),
                                  width: "40",
                                },
                              }),
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          attrs: {
                            cols: "12",
                            sm: "9",
                            md: "12",
                            "align-self": "end",
                          },
                        },
                        [
                          _vm.sideText == ""
                            ? _c("span", { staticClass: "sitelink" }, [
                                _vm._v(" DOCTOR CONSULTATION "),
                              ])
                            : _c("span", { staticClass: "sitelink" }, [
                                _vm._v(" " + _vm._s(_vm.sideText) + " "),
                              ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-col",
            {
              attrs: {
                cols: _vm.loaderType == 6 ? "12" : "10",
                md: _vm.loaderType == 6 ? "12" : "11",
              },
            },
            [
              _vm.loaderType == 1
                ? [
                    _c(
                      "b-row",
                      { attrs: { "align-h": "end" } },
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "12", md: "10", "offset-md": "2" } },
                          [
                            _c("div", { staticClass: "image-holder" }, [
                              _c("img", {
                                staticClass: "float-right",
                                attrs: {
                                  id: "patternImage",
                                  src: _vm.image,
                                  alt: "Lilac pattern",
                                },
                              }),
                            ]),
                            _c("h1", { staticClass: "mt-4 heading" }, [
                              _vm._v(" " + _vm._s(_vm.quote) + " "),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.loaderType == 2
                ? [
                    _c(
                      "b-row",
                      {
                        staticClass: "text-holder",
                        staticStyle: { height: "100%" },
                        attrs: { "align-v": "center", "no-gutters": "" },
                      },
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "12", "offset-md": "2" } },
                          [
                            _c(
                              "p",
                              { staticClass: "text-uppercase sub-title" },
                              [
                                _c("b", [
                                  _vm._v("starting menopause consultation"),
                                ]),
                              ]
                            ),
                            _c(
                              "h1",
                              {
                                staticClass: "heading",
                                staticStyle: {
                                  "line-height": "58px",
                                  "letter-spacing": "-0.02em",
                                },
                              },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      {
                                        attrs: { cols: "12", "offset-sm": "1" },
                                      },
                                      [_vm._v(" On your way ")]
                                    ),
                                    _c(
                                      "b-col",
                                      {
                                        attrs: { cols: "12", "offset-sm": "2" },
                                      },
                                      [_vm._v(" to a better life! ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.loaderType == 3
                ? [
                    _c(
                      "b-row",
                      {
                        staticClass: "text-holder",
                        staticStyle: { height: "100%" },
                        attrs: { "align-v": "center" },
                      },
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "h1",
                              {
                                staticClass: "heading",
                                staticStyle: {
                                  "line-height": "62px",
                                  "letter-spacing": "-0.02em",
                                },
                              },
                              [
                                _c(
                                  "b-row",
                                  { attrs: { "no-gutters": "" } },
                                  [
                                    _c("b-col", { attrs: { cols: "12" } }, [
                                      _vm._v(" Hormone "),
                                    ]),
                                    _c(
                                      "b-col",
                                      {
                                        attrs: { cols: "12", "offset-md": "1" },
                                      },
                                      [_vm._v(" Replacement ")]
                                    ),
                                    _c(
                                      "b-col",
                                      {
                                        attrs: { cols: "12", "offset-md": "2" },
                                      },
                                      [_vm._v(" Therapy~ ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              { staticClass: "mt-6" },
                              [
                                _c("b-col", { attrs: { "offset-md": "4" } }, [
                                  _c(
                                    "p",
                                    {
                                      staticStyle: {
                                        "font-size": "24px",
                                        "line-height": "30px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " HRT replaces the estrogen you make less of at peri - and menopause. It is safe, effective, and FDA approved. "
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.loaderType == 5
                ? [
                    _c(
                      "b-row",
                      {
                        staticClass: "text-holder",
                        staticStyle: { height: "100%" },
                        attrs: { "align-v": "center" },
                      },
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "12", md: "6", "offset-md": "3" } },
                          [
                            _vm._t("text", function () {
                              return [
                                _c("p", { staticClass: "text-uppercase" }, [
                                  _vm._v(_vm._s(_vm.text)),
                                ]),
                              ]
                            }),
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  pill: "",
                                  size: "lg",
                                  variant: "info",
                                },
                                on: { click: _vm.clickHandler },
                              },
                              [_vm._v(_vm._s(_vm.buttonText))]
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.loaderType == 6
                ? [
                    _c(
                      "b-row",
                      {
                        staticClass: "text-holder",
                        staticStyle: { height: "100%" },
                        attrs: { "align-v": "center" },
                      },
                      [
                        _c("LottieAnimation", {
                          attrs: {
                            path: "./animations/lf20_hlqeyzf2.json",
                            loop: true,
                            width: 250,
                            height: 250,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }