<template>
  <b-col cols="12" class="cart-content">
    <b-row align-v="stretch" style="height: 100%">
      <b-col
        cols="12"
        align-self="center"
        v-if="productList && productList.length == 0"
      >
        <h2 class="heading mb-1 text-center mb-3">
          Oops! Your cart is empty.
        </h2>
        <p class="text-center">
          <b-button variant="primary" @click="redirectToTreatments"
            >View Our Treatments</b-button
          >
        </p>
      </b-col>
      <b-col cols="12" v-else>
        <b-row align-v="stretch" style="height: 100%">
          <b-col align-self="start">
            <h3
              class="bold-font text-right mt-3"
              style="letter-spacing: 0.16em;"
            >
              CART
            </h3>
            <cartIcon number="1" class="mt-5" />
          </b-col>
          <b-col cols="12 mt-3 mb-3" align-self="center">
            <div
              class="product"
              v-for="(item, index) in productList"
              v-bind:key="index"
            >
              <b-icon
                icon="x"
                font-scale="1.5"
                class="remove cursor"
                @click="removeProduct(item.index)"
              ></b-icon>
              <div class="product-img">
                <img
                  :src="
                    item.image ? item.image : 'https://via.placeholder.com/150'
                  "
                  :alt="item.name"
                />
              </div>
              <div class="product-content">
                <h2 class="heading mb-2 color-inherit">{{ item.name }}</h2>
                <p class="small-text">
                  {{ item.dosage }} — ${{ item.selectedPricing.price }}
                </p>
                <p class="pt-2 pb-2 mb-0 small-text" v-line-clamp="2">
                  {{ item.description }}
                </p>
                <div class="quantity mt-2">
                  <b-icon
                    icon="dash"
                    class="cursor"
                    @click="decrease(item.index)"
                  >
                  </b-icon>
                  <input
                    type="number"
                    min="0"
                    placeholder="0"
                    v-model="item.quantity"
                    @change="update(item.index, item.quantity)"
                  />
                  <b-icon
                    icon="plus"
                    class="cursor"
                    @click="increase(item.index)"
                  ></b-icon>
                </div>
              </div>
            </div>

            <b-list-group flush class="product-amount">
              <b-list-group-item class="d-flex justify-content-between">
                Order Sub-total
                <span>${{ subTotal }}</span>
              </b-list-group-item>
              <b-list-group-item class="d-flex justify-content-between">
                <b class="bold-font">Grand Total</b>
                <span>${{ total }}</span>
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col align-self="end" class="pb-4">
            <b-button pill block variant="primary" @click="checkState"
              >Next</b-button
            >
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import config from "../../../config";
import cartIcon from "@/components/cart/CartIcon";
export default {
  props: {
    cartUpdate: {
      type: String,
      default: "",
    },
  },
  components: {
    cartIcon,
  },
  data() {
    return {
      productList: [],
      total: 0,
      subTotal: 0,
    };
  },
  watch: {
    cartUpdate() {
      this.productList = JSON.parse(this.cartUpdate);
      this.calculate();
    },
  },
  methods: {
    redirectToTreatments() {
      const params = {
        content: `${process.env.VUE_APP_WEBSITE_URL}/treatments`,
      };
      if (window.parent) {
        window.parent.postMessage(params, process.env.VUE_APP_WEBSITE_URL);
      }
    },
    clearCart() {
      this.productList = [];
      this.total = 0;
      this.subTotal = 0;
    },
    increase(index) {
      if (!this.productList) return;
      this.productList = this.productList.reduce((result, item) => {
        if (item.index === index) {
          item.quantity++;
        }
        result.push(item);
        return result;
      }, []);

      this.calculate();
    },
    decrease(index) {
      if (!this.productList) return;
      this.productList = this.productList.reduce((result, item) => {
        if (item.index === index) {
          if (item.quantity == 0) item.quantity = 0;
          else item.quantity--;
        }
        if (item.quantity > 0) {
          result.push(item);
        }
        return result;
      }, []);

      this.calculate();
    },
    update(index, qty) {
      if (!this.productList) return;
      this.productList = this.productList.reduce((result, item) => {
        if (item.index === index) {
          if (item.quantity == 0) item.quantity = 0;
          else item.quantity = qty;
        }
        result.push(item);
        return result;
      }, []);

      this.calculate();
    },
    checkState() {
      if (window.localStorage.getItem("accessToken")) {
        this.$emit("set-screen", "shipping");
      } else {
        this.$emit("set-screen", "register", true);
      }
    },
    removeProduct(index) {
      if (!this.productList) return;
      this.productList = this.productList.filter((p) => p.index !== index);

      this.calculate();
    },
    calculate() {
      if (!this.productList) return;
      this.subTotal = this.productList.reduce(
        (a, b) => a + parseFloat(b.selectedPricing.price * b.quantity),
        0
      );

      this.total = this.subTotal;
      window.localStorage.setItem("cart", JSON.stringify(this.productList));

      let token = window.localStorage.getItem("accessToken");
      let params = {
        token: token ? token : null,
        sessionId: window.localStorage.getItem("sessionId"),
        products: window.localStorage.getItem("cart"),
        event: "",
      };
      window.parent.postMessage(params, process.env.VUE_APP_WEBSITE_URL);
    },
  },
  mounted() {
    let token = window.localStorage.getItem("accessToken");
    this.showIcon = token ? false : true;
    let cart = window.localStorage.getItem("cart");
    // console.log(cart);
    if (cart) {
      this.productList = JSON.parse(cart);
    }
  },
};
</script>

<style lang="scss" scoped>
.product {
  position: relative;
  display: flex;
  align-items: flex-start;
  background-color: $white;
  margin-bottom: 1.5rem;
  box-shadow: 0px 8px 10px #f2f6ef;

  .remove {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .product-img {
    flex: 140px 0 0;
    max-width: 140px;
    align-self: stretch;
    background-color: #e8f2f1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .product-content {
    padding: 1rem;

    input[type="number"] {
      width: 40px;
      text-align: center;
      border: 0;
      -moz-appearance: textfield;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
</style>
