var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.isFetchingOnboardingStatus,
          expression: "!isFetchingOnboardingStatus",
        },
      ],
      staticClass: "p-0",
      attrs: { fluid: "" },
    },
    [
      _c("top-bar", {
        attrs: {
          username: _vm.name,
          "profile-pic": _vm.profileUrl,
          id: _vm.id,
        },
      }),
      _c(
        "b-container",
        {
          class: this.$route.name == "messages" ? "p-0" : "pl-0 pr-0",
          staticStyle: { overflow: "hidden", "margin-top": "67px" },
          attrs: { fluid: "" },
        },
        [_c("vue-page-transition", [_c("router-view")], 1)],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }