<template>
  <b-col cols="12" class="cart-shipping">
    <b-row align-v="stretch" style="height: 100%">
      <b-col align-self="start">
        <h3 class="bold-font text-right mt-3" style="letter-spacing: 0.16em;">
          SHIPPING
        </h3>
        <cartIcon number="3" class="my-3" />
      </b-col>
      <b-col cols="12" align-self="center">
        <h2 class="heading mb-1 text-center">Shipping Information</h2>
        <p class="text-center">
          Where would you like us to ship this?
        </p>
        <b-alert :show="alert.show" variant="warning">{{
          alert.message
        }}</b-alert>

        <div role="group" class="mb-3">
          <label class="input-label" for="address">Street Address*:</label>
          <b-form-input
            class="pl-0 fs-mask"
            id="address1"
            v-model="address1"
            type="text"
          ></b-form-input>
        </div>

        <div role="group" class="mb-3">
          <label class="input-label" for="address2">Apartment/Suite*:</label>
          <b-form-input
            id="address2"
            class="pl-0 fs-mask"
            v-model="address2"
            type="text"
          ></b-form-input>
        </div>

        <div role="group" class="mb-3">
          <label class="input-label" for="state">State*:</label>
          <v-select
            class="cart-state fs-mask"
            append-to-body
            :calculate-position="withPopper"
            v-model="state"
            label="text"
            :options="stateoptions"
            :reduce="(stateoptions) => stateoptions.value"
          >
            <template #open-indicator="{ attributes }">
              <b-icon v-bind="attributes" icon="chevron-down"></b-icon>
            </template>
          </v-select>
        </div>

        <b-row>
          <b-col cols="6" md="6">
            <div role="group" class="mb-3">
              <label class="input-label" for="city">City*:</label>
              <b-form-input
                class="pl-0 fs-mask"
                id="city"
                v-model="city"
                type="text"
              ></b-form-input>
            </div>
          </b-col>
          <b-col cols="6" md="6">
            <div role="group" class="mb-3">
              <label class="input-label" for="zipcode">Zip Code*:</label>
              <b-form-input
                class="pl-0 fs-mask"
                id="zipcode"
                v-model="zipcode"
                type="text"
              ></b-form-input>
            </div>
          </b-col>
        </b-row>
      </b-col>

      <b-col cols="12" align-self="end">
        <b-button
          pill
          block
          variant="link"
          class="mt-3 mb-3"
          @click="$emit('set-screen', 'cart')"
          >Back</b-button
        >
        <b-button
          pill
          block
          variant="primary"
          class="mt-3 mb-3"
          @click="submitShipping"
          >Continue</b-button
        >
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import popper from "@/assets/js/popper.js";
// Services
import { LookUpService } from "@/services/lookup.service";
import { ShippingService } from "@/services/shipping.service";
// Components
import cartIcon from "@/components/cart/CartIcon";

export default {
  mixins: [popper],
  props: {
    cartUpdate: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    cartIcon,
  },
  data() {
    return {
      alert: {
        show: false,
        message: "",
      },

      stateoptions: [],
      shippingId: "",
      address1: "",
      address2: "",
      state: "",
      city: "",
      zipcode: "",
    };
  },
  methods: {
    async submitShipping() {
      this.alert.show = false;
      try {
        let data = {
          address1: this.address1,
          address2: this.address2,
          country: this.country,
          zipcode: this.zipcode,
          city: this.city,
          state: this.state,
        };
        data = this.clean(data);
        let res;
        if (this.shippingId) {
          res = await ShippingService.updateShipping(this.shippingId, data);
        } else {
          res = await ShippingService.addShipping(data);
        }
        if (res && res.data) {
          this.$emit("set-screen", "billing");
        }
      } catch (err) {
        this.alert.message = err.message;
        this.alert.show = true;
      }
    },
    clean(obj) {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName] == ""
        ) {
          delete obj[propName];
        }
      }
      return obj;
    },
  },
  async mounted() {
    // Get states
    if (this.stateoptions.length == 0) {
      const { data } = await LookUpService.getLookUp("state");
      data.forEach((state) => {
        this.stateoptions.push({ value: state.id, text: state.name });
      });
    }

    // Get shipping
    try {
      let res = await ShippingService.listShipping();
      if (res && res.data) {
        let data = res.data[0];
        // console.log("Shipping ", data);
        if (data && data.id) {
          this.shippingId = data.id;
          this.address1 = data.address1;
          this.address2 = data.address2;
          this.state = data.state;
          this.zipcode = data.zipcode;
          this.city = data.city;
          this.country = data.country;
        }
      }
    } catch (error) {
      if (error.message) {
        // console.log("Error", error.message);
      }
    }
  },
};
</script>
