import { BaseService } from "./base.service";
import { ErrorWrapper, ResponseWrapper } from "./util";

export class ConfigurationService extends BaseService {
  static async get() {
    try {
      const response = await this.request({ auth: true }, "v2").get(
        "configuration"
      );

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
