export default {
  namespaced: true,
  state: {
    isModalOpen: false
  },
  mutations: {
    SET_MODAL_STATE(state, modalState) {
      state.isModalOpen = modalState
    }
  },
  getters: {},
  actions: {}
}