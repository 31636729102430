var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hydrated
    ? _c(
        "div",
        { class: _vm.formControl ? "login-page" : "", attrs: { id: "app" } },
        [
          _vm.formControl
            ? [
                _c(
                  "b-container",
                  {
                    staticClass: "login-cont cover-image",
                    style: {
                      backgroundImage:
                        this.$route.name == "login" ||
                        this.$route.name == "new-password"
                          ? "url(" +
                            require("@/assets/images/login/bg.png") +
                            ")"
                          : this.$route.name == "forgot-password"
                          ? "url(" +
                            require("@/assets/images/login/fp.png") +
                            ")"
                          : "",
                    },
                    attrs: { fluid: "" },
                  },
                  [
                    _c(
                      "b-row",
                      {
                        staticStyle: { height: "100vh" },
                        attrs: { "align-v": "stretch" },
                      },
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "12", lg: "5", xxl: "4" } },
                          [
                            _c(
                              "b-row",
                              {
                                staticStyle: { height: "100%" },
                                attrs: { "align-v": "stretch" },
                              },
                              [
                                _c(
                                  "b-col",
                                  {
                                    attrs: {
                                      cols: "12",
                                      "align-self": "start",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-navbar-brand",
                                      {
                                        staticClass: "c-logo",
                                        attrs: { to: "/website" },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/images/logo/logo_w_black.svg"),
                                            alt: "",
                                          },
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  {
                                    attrs: {
                                      cols: "12",
                                      "align-self": "start",
                                    },
                                  },
                                  [
                                    _c(
                                      "vue-page-transition",
                                      [_c("router-view")],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          {
                            staticClass: "pb-4",
                            attrs: { cols: "12", "align-self": "end" },
                          },
                          [
                            _c(
                              "b-row",
                              { attrs: { "align-v": "center" } },
                              [
                                _c("b-col", { attrs: { cols: "6", lg: "6" } }, [
                                  _c("p", { staticClass: "mb-0" }, [
                                    _vm._v("©WINONA " + _vm._s(_vm.getYear)),
                                  ]),
                                ]),
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "text-right d-none d-md-block",
                                    attrs: { cols: "6", lg: "6" },
                                  },
                                  [
                                    _c(
                                      "b-row",
                                      { attrs: { "align-h": "end" } },
                                      [
                                        _c(
                                          "b-col",
                                          {
                                            staticClass: "text-right",
                                            attrs: { cols: "6", lg: "4" },
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "d-none d-lg-block mb-0",
                                              },
                                              [
                                                _c("router-link", {
                                                  attrs: {
                                                    to: "/website",
                                                    custom: "",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function ({
                                                          navigate,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "text-white",
                                                                attrs: {
                                                                  href: "javascript:void(0)",
                                                                  role: "link",
                                                                },
                                                                on: {
                                                                  click:
                                                                    navigate,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " BYWINONA.COM"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    1515641255
                                                  ),
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "d-block d-lg-none mb-0",
                                              },
                                              [
                                                _c("router-link", {
                                                  attrs: {
                                                    to: "/website",
                                                    custom: "",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function ({
                                                          navigate,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "text-primary",
                                                                attrs: {
                                                                  href: "javascript:void(0)",
                                                                  role: "link",
                                                                },
                                                                on: {
                                                                  click:
                                                                    navigate,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " BYWINONA.COM "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    1191751532
                                                  ),
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "b-col",
                                          { attrs: { cols: "6", lg: "4" } },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "d-none d-lg-block mb-0",
                                              },
                                              [
                                                _c("router-link", {
                                                  attrs: {
                                                    to: "/website/contact",
                                                    custom: "",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function ({
                                                          navigate,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "text-white",
                                                                attrs: {
                                                                  href: "javascript:void(0)",
                                                                  role: "link",
                                                                },
                                                                on: {
                                                                  click:
                                                                    navigate,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " CONTACT US "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    648401733
                                                  ),
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "d-block d-lg-none mb-0",
                                              },
                                              [
                                                _c("router-link", {
                                                  attrs: {
                                                    to: "/website/contact",
                                                    custom: "",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function ({
                                                          navigate,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "text-primary",
                                                                attrs: {
                                                                  href: "javascript:void(0)",
                                                                  role: "link",
                                                                },
                                                                on: {
                                                                  click:
                                                                    navigate,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " CONTACT US "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    280667662
                                                  ),
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : [_c("vue-page-transition", [_c("router-view")], 1)],
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }