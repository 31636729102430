<template>
  <div>

  </div>
</template>

<script>
import config from '../../../config';

export default {
  methods: {
    async receiveMessage (event) {
      if(event.origin !== process.env.VUE_APP_WEBSITE_URL) return;
      this.parent = event;
      let data = event.data;
      window.localStorage.setItem(data.name, data.value);
    }
  },
  mounted () {
    window.addEventListener('message', this.receiveMessage, false);
  }
}
</script>