import { BaseService } from "./base.service";
import { ErrorWrapper, ResponseWrapper } from "./util";

export class ReferralService extends BaseService {
  static async sendReferralEmail(link, email) {
    try {
      const response = await this.request({ auth: true }).post(
        `/referral/patient`,
        {
          referralLink: link,
          email: email,
        }
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async overwritePromocode(refcode) {
    try {
      const response = await this.request({ auth: true }).post(
        "/referral/overwrite",
        {
          referralcode: refcode,
        }
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
