<template>
  <b-container fluid style="background-color: #FBFFF9">
    <b-row align-v="stretch" style="height: 100vh">
      <CartLogin
        v-if="screen == 'login'"
        @set-screen="setScreen"
        :redirect="loginWithRedirect.status"
        :redirectURL="loginWithRedirect.url"
      />
      <CartForgotPassword
        v-if="screen == 'forgotpassword'"
        @set-screen="setScreen"
      />
      <CartRegistration
        v-if="screen == 'register'"
        @set-screen="setScreen"
        :cartPath="cartPath"
      />
      <CartAccount
        v-if="screen == 'account'"
        :firstname="userData.firstname"
        @logout="logout"
      />
      <CartContent
        v-show="screen == 'cart'"
        @set-screen="setScreen"
        :cartUpdate="cartUpdate"
        ref="cartContent"
      />
      <CartShipping v-if="screen == 'shipping'" @set-screen="setScreen" />
      <CartBilling v-if="screen == 'billing'" @set-screen="setScreen" />
      <CartComplete
        v-if="screen == 'checkout-complete'"
        @clear-cart="clearCart"
      />
    </b-row>
  </b-container>
</template>

<script>
import config from "../../config";
// Do not remove this import
import wForm from "@/components/form/Form";
// Services
import { UsersService } from "@/services/users.service";
import { AuthService } from "@/services/auth.service";
// Components
import CartLogin from "@/components/cart/CartLogin";
import CartAccount from "@/components/cart/CartAccount";
import CartRegistration from "@/components/cart/CartRegistration";
import CartContent from "@/components/cart/CartContent";
import CartForgotPassword from "@/components/cart/CartForgotPassword";
import CartShipping from "@/components/cart/CartShipping";
import CartBilling from "@/components/cart/CartBilling";
import CartComplete from "@/components/cart/CartComplete";

export default {
  name: "cart",
  components: {
    CartLogin,
    CartAccount,
    CartRegistration,
    CartForgotPassword,
    CartContent,
    CartShipping,
    CartBilling,
    CartComplete,
  },
  data() {
    return {
      screen: "cart",
      userData: {},
      parent: {},
      cartPath: false,
      cartUpdate: "",
      loginWithRedirect: {
        status: false,
        url: "",
      },
    };
  },
  methods: {
    setScreen(newScreen, cartPath = false) {
      this.screen = newScreen;
      this.cartPath = cartPath;
    },
    async getUser() {
      try {
        const { data } = await UsersService.getCurrent();
        this.userData = Object.assign({}, this.userData, data);
        this.setScreen("account");
      } catch (err) {
        if (err.message) {
          // console.log('Error', err.message);
        }
      }
    },
    async receiveMessage(event) {
      if (event.origin !== process.env.VUE_APP_WEBSITE_URL) return;
      // console.log('cart-event', event.origin, event.data);

      this.parent = event;
      let data = event.data;

      if (data.cookie)
        window.localStorage.setItem("winona-cookie-id", data.cookie);

      if (data && data.event) {
        switch (data.event) {
          case "logout":
            this.logout();
            break;
          case "login":
            this.setScreen("login");
            break;
          case "login-with-redirect":
            if (window.localStorage.getItem("token")) {
              await this.redirectWithToken();
            } else {
              this.loginWithRedirect.status = true;
              this.loginWithRedirect.url = data.redirectURL;
              this.setScreen("login");
            }
            break;
          case "account-open":
            this.setScreen("account");
            break;
          case "cart-open":
            this.setScreen("cart");
            this.cartUpdate = data.products;
            break;
        }
      }
    },
    async redirectWithToken() {
      const { data } = await AuthService.getOTTFromToken();
      if (data) {
        let params = {
          event: "redirect",
          url: this.loginWithRedirect.url,
          token: data.onetimetoken,
        };
        if (window.parent) {
          window.parent.postMessage(params, process.env.VUE_APP_WEBSITE_URL);
        }
      }
    },
    logout() {
      let params = {
        event: "logout",
      };
      if (window.parent) {
        window.parent.postMessage(params, process.env.VUE_APP_WEBSITE_URL);
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("accessToken");
        window.localStorage.removeItem("isAuthenticated");
        window.localStorage.removeItem("winona-cookie-id");
        window.location.reload();
      }
    },
    clearCart() {
      this.$refs.cartContent.clearCart();
    },
  },
  created() {
    window.addEventListener("message", this.receiveMessage, false);

    let token = window.localStorage.getItem("token");

    // if (!token) {
    //   this.screen = "login";
    // } else {
    //   if (Object.keys(this.userData).length == 0) {
    //     this.getUser();
    //   }
    // }

    if (token) {
      if (Object.keys(this.userData).length == 0) {
        this.getUser();
      }
    }

    if (window.localStorage.getItem("cartLogin") == 1) {
      let params = {
        token: token ? token : null,
        sessionId: window.localStorage.getItem("sessionId"),
        products: window.localStorage.getItem("cart"),
        event: "signup",
        user: this.userData,
      };
      window.parent.postMessage(params, process.env.VUE_APP_WEBSITE_URL);
      window.localStorage.setItem("cartLogin", 0);
    }
  },
};
</script>
