import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util';

export class StripeService extends BaseService {

  static async updateSource(source) {
    // try {
    //   const response = await this.request({ auth: true }).put(`/stripe/customer`, source)
    //   return new ResponseWrapper(response, response.data.body);
    // } catch (error) {
    //   throw new ErrorWrapper(error);
    // }
    try {
      const response = await this.request({ auth: true }).put(`/payment/source`, source)
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getSource() {
    try {
      const response = await this.request({ auth: true }).get(`/payment`)
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}