<template>
  <!-- Email input -->
  <b-col cols="12" class="cart-recover-password">
    <b-row align-v="stretch" style="height: 100%" class="py-5">
      <template v-if="fpScreen == 'email'">
        <b-col cols="12" align-self="start">
          <h1 class="mb-3 heading text-center">Recover Password</h1>
          <p class="text-center label-text">
            Don't worry, it happens to the best of us.
          </p>
        </b-col>
        <b-col cols="12" align-self="center" class="mb-4">
          <b-alert :show="alert.show" variant="warning">{{
            alert.message
          }}</b-alert>
          <label for="email" class="input-label">Email Address:</label>
          <b-form-input
            id="email"
            class="pl-0 input-bg fs-mask"
            v-model="email"
            type="email"
            :state="emailState"
            placeholder="name@address.com"
            required
          ></b-form-input>
          <!-- This will only be shown if the preceding input has an invalid state -->
          <b-form-invalid-feedback id="input-live-feedback">
            The email must have '@email.com'
          </b-form-invalid-feedback>

          <div class="text-center mt-6">
            <small class="small-text">
              Do you remember your password?
              <a href="javascript:void(0)" @click="$emit('set-screen', 'login')"
                >Try logging in</a
              >
            </small>
          </div>
        </b-col>
        <b-col cols="12" align-self="end">
          <b-button
            variant="primary"
            class="mb-3"
            block
            pill
            @click="sendForgotPassword"
            >Send</b-button
          >
        </b-col>
      </template>

      <!-- Email sent -->
      <template v-if="fpScreen == 'sent'">
        <b-col cols="12" align-self="center">
          <h1 class="text-center mb-3 heading">It's on the way</h1>
          <p class="text-center label-text">
            Please check your inbox and follow the instructions to reset your
            password.
          </p>
          <small class="d-block my-6 text-center small-text"
            >Didn't receive the link?
            <a href="javascript:void(0)" @click="fpScreen = 'email'"
              >Resend Link</a
            ></small
          >
        </b-col>
        <!-- <b-col cols="12" align-self="end">
          <b-button variant="primary" class="mb-3" block pill @click="$emit('set-screen', 'login')">Back to Login</b-button>
        </b-col> -->
      </template>

      <!-- New Password form -->
      <template v-if="fpScreen == 'newpassword'">
        <b-col cols="12" align-self="start">
          <h1 class="text-center mb-3 heading">Recover Password</h1>
          <p class="text-center label-text">Create a strong password</p>
        </b-col>

        <b-col cols="12" align-self="center">
          <b-alert :show="alert.show" variant="warning">{{
            alert.message
          }}</b-alert>
          <div role="group" class="mb-4">
            <label for="password" class="input-label">New Password:</label>
            <b-form-input
              id="password"
              class="pl-0 input-bg fs-mask"
              v-model="password"
              type="password"
              :state="passwordState"
              required
            ></b-form-input>
            <!-- This will only be shown if the preceding input has an invalid state -->
            <b-form-invalid-feedback id="input-live-feedback">
              Min. 8 characters
              <!-- Min. 8 characters with at least one capital letter, a number and a
              special character -->
            </b-form-invalid-feedback>
          </div>

          <div role="group" class="mb-4">
            <label for="cpassword" class="input-label">Confirm Password:</label>
            <b-form-input
              id="cpassword"
              class="pl-0 input-bg fs-mask"
              v-model="cpassword"
              type="password"
              :state="cpasswordState"
              required
            ></b-form-input>
            <!-- This will only be shown if the preceding input has an invalid state -->
            <b-form-invalid-feedback id="input-live-feedback">
              Your password does not match
            </b-form-invalid-feedback>
          </div>
          <small class="d-block my-6 text-center"
            >Do you remember your password?
            <a href="javascript:void(0)" @click="$emit('set-screen', 'login')"
              >Try logging in</a
            ></small
          >
        </b-col>

        <b-col cols="12" align-self="end">
          <b-button
            variant="primary"
            size="lg"
            class="mb-3"
            block
            pill
            @click="newPassword"
            >Change Password</b-button
          >
        </b-col>
      </template>

      <template v-if="fpScreen == 'passwordChange'">
        <b-col cols="12" align-self="center">
          <h1 class="text-center mb-3 heading">
            Your password has been changed
          </h1>
          <p class="text-center label-text">
            You can now use your new password to log in to your account.
          </p>
        </b-col>
        <b-col cols="12" align-self="end">
          <b-button
            variant="primary"
            class="mb-3"
            block
            pill
            @click="$emit('set-screen', 'login')"
            >Login</b-button
          >
        </b-col>
      </template>
    </b-row>
  </b-col>
</template>

<script>
// Services
import { UsersService } from "@/services/users.service";
// Mixins
import mixins from "@/components/form/mixins";

export default {
  mixins: [mixins],
  data() {
    return {
      alert: {
        show: false,
        message: "",
      },
      // Toggles the "it's on the way" screen.
      fpScreen: "email",
      email: "",
      password: "",
      cpassword: "",
    };
  },
  methods: {
    async sendForgotPassword() {
      this.alert.show = false;

      if (!this.email) {
        this.alert.message = "Please enter your email.";
        this.alert.show = true;
        return;
      }

      try {
        let res = await UsersService.forgotPassword(this.email);
        this.fpScreen = "sent";
      } catch (err) {
        this.alert.message = err.message;
        this.alert.show = true;
      }
    },
    async newPassword() {
      this.loginAlert.show = false;
      try {
        // this.changeButton = true
        if (this.password !== this.cpassword) {
          return this.showAlert("Password did not match");
        }

        var jwttoken = this.$route.query.v;
        var _code = this.$route.query.c;
        var decoded = VueJwtDecode.decode(jwttoken);

        var confirmpassword = {
          email: decoded.username,
          code: _code,
          password: this.password,
        };
        //console.log('confirmpassword', confirmpassword)
        await UsersService.confirmPassword(confirmpassword);
        this.changeButton = true;
      } catch (error) {
        if (error.message) {
          return this.showAlert(error.message);
        }
      }
    },
  },
};
</script>
