import { mbToBytes } from "@/utils/number";

export const MAX_SIZE_MB = 25;

export const DEFAULT_IMAGE_QUALITY = 0.7;

export const MAX_SIZE_BYTES = mbToBytes(MAX_SIZE_MB);

export const ACCEPTED_FILE_PATTERNS =
  "image/jpeg, image/png, image/tiff, image/heic, image/heif, application/pdf, .heic, .heif";

export const EXCLUDED_EXT = [".jfif"];

export const GENERIC_FILE_TYPES = {
  PDF: "pdf",
  JPG: "jpg",
  PNG: "png",
  HEIC: "heic",
  TIF: "tif",
};

export default {
  MAX_SIZE_MB,
  MAX_SIZE_BYTES,
  DEFAULT_IMAGE_QUALITY,
  ACCEPTED_FILE_PATTERNS,
  GENERIC_FILE_TYPES,
  MAX_SIZE_MB,
  EXCLUDED_EXT,
};
