// LocalStorageService.js
const LocalStorageService = (function () {

	var _service;

	function _getService() {
		if (!_service) {
			_service = this
			return _service
		}
		return _service
	}

	function _setToken(token) {
		localStorage.setItem('token', token)
		localStorage.setItem('isAuthenticated', true)
		// localStorage.setItem('refresh_token', token)
	}

	function _setItem(key, value) {
		localStorage.setItem(key, value)
	}

	function _getItem(key) {
		return localStorage.getItem(key)
	}

	function _removeItem(key) {
		return localStorage.removeItem(key)
	}

	function _getAccessToken() {
		return localStorage.getItem('token')
	}

	// function _getRefreshToken() {
	// 	return localStorage.getItem('refresh_token')
	// }

	function _clearToken() {
		localStorage.removeItem('token')
		localStorage.removeItem('isAuthenticated')
		localStorage.removeItem('userid')
		// localStorage.removeItem('refresh_token')
	}

	function _setuserID(userid) {
		localStorage.setItem('userid', userid)
	}

	function _getuserID() {
		return localStorage.getItem('userid')
	}

	return {
		getService: _getService,
		setToken: _setToken,
		getAccessToken: _getAccessToken,
		// getRefreshToken : _getRefreshToken,
		clearToken: _clearToken,
		getUserID: _getuserID,
		setuserID: _setuserID,
		setItem: _setItem,
		getItem: _getItem,
		removeItem: _removeItem
	}
})()

export default LocalStorageService