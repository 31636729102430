<template>
  <div class="page-loader">
    <h1 class="heading">Redirection in progress</h1>
    <p class="animate">please wait...</p>
  </div>
</template>

<script>
import { OnboardingService } from '@/services/onboarding.service';

export default {
  name: 'redirect',
  data () {
    return {
      userOnboarding: {},
      step: 0
    }
  },
  async mounted () {
    if(!window.localStorage.getItem('accessToken')) {
      this.$router.push('/login').catch(()=>{});
    } else {
      try {
        const { data } = await OnboardingService.getPatientOnboarding();
        this.step = data.onboardingstep;
        if(this.step == 1) {
          this.$router.push('/eligibility').catch(()=>{});
        } else if ((this.step >= 2 && this.step < 3) || this.step == 1.5 || this.step == 3.1) {
          this.$router.push('/health-questions').catch(()=>{});
        } else if (this.step >= 3 && this.step < 5) {
          this.$router.push('/treatment-options').catch(()=>{});
        } else if (this.step >= 5 && this.step < 6) {
          this.$router.push('/identity-verification').catch(()=>{});
        } else if (this.step >= 6 && data.onboardingstatus != 'DONE') {
          this.$router.push('/checkout').catch(()=>{});
        } else {
          this.$router.push('/').catch(()=>{});
        }
      } catch (err) {
        if (err.message == 'Invalid token') {
          window.localStorage.removeItem('accessToken');
          window.localStorage.removeItem('token')
          this.$router.push('/login').catch(()=>{});
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.heading {
  color: $white !important;
}

.page-loader {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  background-color: $primary;
  color: $white;

  .animate {
    animation-name: blinking;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
}

@keyframes blinking {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
}
</style>