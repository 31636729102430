<template>
  <ul class="breadcrumb">
    <li :class="{ active: i + 1 == number }" v-for="(n, i) in icon" :key="i">
      <div class="icon-box">
        <v-icon
          :name="n.name"
          :color="i + 1 == number ? '#19191C' : '#BDBDBD'"
          size="20"
        ></v-icon>
      </div>
      <div class="spacer">
        <b-icon icon="chevron-right"></b-icon>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    number: {
      type: [Number, String],
      default: 1,
    },
  },
  data() {
    return {
      icon: [
        {
          name: "cart",
        },
        {
          name: "account",
        },
        {
          name: "shipping",
        },
        {
          name: "checkout",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.breadcrumb {
  $size: 35px;

  display: flex;
  justify-content: center;

  li {
    display: flex;
    align-items: center;

    &:last-child {
      .spacer {
        display: none;
      }
    }

    .icon-box {
      display: flex;
      align-items: center;
      justify-content: center;
      height: $size;
      width: $size;

      @include media-breakpoint-up(xxl) {
        height: $size * $scale-font;
        width: $size * $scale-font;
      }
    }

    .spacer {
      text-align: center;
      width: $size;
      margin: 0 3px;

      @include media-breakpoint-up(xxl) {
        width: $size * $scale-font;
      }
    }

    .icon-holder {
      margin-top: -$size / 5.5;
    }
  }
}
</style>
