import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util';

export class ShippingService extends BaseService {
  static async listShipping() {
    try {
      const response = await this.request({ auth: true }).get(`/shipping`)
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async defaultShipping() {
    try {
      const response = await this.request({ auth: true }).get(`/shipping/default`)
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async updateShipping(id, data) {
    try {
      const response = await this.request({ auth: true }).put(`/shipping/${id}`, data)
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async addShipping(data) {
    try {
      const response = await this.request({ auth: true }).post(`/shipping`, data)
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}