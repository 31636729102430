import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util';

export class NotificationService extends BaseService{
  static async getNotifications(nextPage) {
    try {
      const response = await this.request({ auth: true }).get(`notification?nextpage=${nextPage}`)
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
  static async markAsRead(id) {
    try {
      const response = await this.request({ auth: true }).put(`notification/${id}/read`)
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
  static async markAllAsRead() {
    try {
      const response = await this.request({ auth: true }).put(`notification/all/read`)
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}