<template>
  <b-col cols="12" class="cart-registration">
    <b-row style="height: 100%" align-v="stretch" class="py-3">
      <b-col align-self="start">
        <h3 class="bold-font text-right mt-3" style="letter-spacing: 0.16em;">
          ACCOUNT
        </h3>
        <cartIcon v-if="cartPath" number="2" class="mt-5" />
      </b-col>

      <b-col cols="12" align-self="start" class="mt-4">
        <h1 class="mb-3 heading text-center">
          <template v-if="cartPath">
            Welcome to Winona!
          </template>
          <template v-else>
            Hello, beautiful!
          </template>
        </h1>
        <p class="label-text text-center">
          <template v-if="cartPath">
            We're here to help you feel your best.
          </template>
          <template v-else>
            Please sign up to continue
          </template>
        </p>
      </b-col>

      <b-col cols="12" align-self="center">
        <b-alert :show="alert.show" variant="warning">{{
          alert.message
        }}</b-alert>
        <b-row class="mt-4">
          <b-col cols="6">
            <div role="group" class="mb-4">
              <label for="firstName" class="input-label">First Name*</label>
              <b-form-input
                id="firstName"
                class="pl-0 fs-mask"
                type="text"
                v-model="firstName"
                autocomplete="off"
                required
              ></b-form-input>
            </div>
          </b-col>

          <b-col cols="6">
            <div role="group" class="mb-4">
              <label for="lastName" class="input-label">Last Name*</label>
              <b-form-input
                id="lastName"
                class="pl-0 fs-mask"
                type="text"
                v-model="lastName"
                autocomplete="off"
                required
              ></b-form-input>
            </div>
          </b-col>
        </b-row>

        <div role="group" class="mb-4">
          <label for="email" class="input-label">Email Address*</label>
          <b-form-input
            id="email"
            class="pl-0 fs-mask"
            type="email"
            v-model="email"
            :state="emailState"
            autocomplete="off"
            required
          ></b-form-input>
          <!-- This will only be shown if the preceding input has an invalid state -->
          <b-form-invalid-feedback id="input-live-feedback">
            The email must have '@email.com'
          </b-form-invalid-feedback>
        </div>

        <div role="group" class="mb-4">
          <label for="password" class="input-label">Password*</label>
          <b-form-input
            id="password"
            class="pl-0 fs-mask"
            v-model="password"
            type="password"
            :state="passwordState"
            required
            autocomplete="off"
          ></b-form-input>
          <!-- This will only be shown if the preceding input has an invalid state -->
          <b-form-invalid-feedback id="input-live-feedback">
            Min. 8 characters
            <!-- Min. 8 characters with at least one capital letter, a number and a
            special character -->
          </b-form-invalid-feedback>
        </div>

        <b-form-checkbox
          id="terms"
          class="checkbox-label"
          v-model="iAgree"
          name="terms"
        >
          I have read and agree to these
          <a :href="termLink" v-on:click.prevent="showDialog(termLink)"
            >Terms &amp; Conditions</a
          >,
          <a :href="privacyLink" v-on:click.prevent="showDialog(privacyLink)"
            >Privacy Policy</a
          >, and
          <a :href="teleLink" v-on:click.prevent="showDialog(teleLink)"
            >Telehealth Consent</a
          >
        </b-form-checkbox>

        <small class="my-5 pt-2 d-block text-center small-text">
          Already have an account?
          <a
            href="javascript:void(0)"
            tabindex="-1"
            @click="$emit('set-screen', 'login')"
            >Log In</a
          >
        </small>
      </b-col>

      <b-col cols="12" align-self="end">
        <b-button
          :disabled="buttonDisabled"
          pill
          block
          variant="primary"
          @click="register"
        >
          Register
        </b-button>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import config from "../../../config";
import mixins from "@/components/form/mixins";
// Services
import { AuthService } from "@/services/auth.service";
import cartIcon from "@/components/cart/CartIcon";

export default {
  props: {
    cartPath: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    cartIcon,
  },
  mixins: [mixins],
  data() {
    return {
      termLink: `${process.env.VUE_APP_WEBSITE_URL}/terms-conditions/`,
      privacyLink: `${process.env.VUE_APP_WEBSITE_URL}/privacy-policy/`,
      teleLink: `${process.env.VUE_APP_WEBSITE_URL}/telehealth-informed-consent/`,

      alert: {
        show: false,
        message: "",
      },

      firstName: "",
      lastName: "",
      email: "",
      password: "",
      iAgree: false,

      buttonDisabled: false,
    };
  },
  computed: {
    buttonText() {
      return !this.buttonDisabled ? "Register" : "Processing";
    },
  },
  methods: {
    showDialog(contentLink) {
      let params = {
        content: contentLink,
      };
      if (window.parent) {
        window.parent.postMessage(params, process.env.VUE_APP_WEBSITE_URL);
      }
    },
    async register() {
      this.alert.show = false;
      this.buttonDisabled = true;
      try {
        if (this.iAgree) {
          const { data: auth } = await AuthService.signupNonRx({
            firstname: this.firstName,
            lastname: this.lastName,
            email: this.email,
            password: this.password,
          });
          FS.identify(auth.userid);
          AuthService.setBearer(auth.idToken);
          window.localStorage.setItem("cartLogin", 1);
          if (this.cartPath) {
            this.$emit("set-screen", "shipping", true);
          } else {
            window.location.reload();
          }
        } else {
          this.alert.message =
            "Please state if you agree to our Terms & Conditions, Privacy Policy, and Telehealth Consent";
          this.alert.show = true;
          this.buttonDisabled = false;
        }
      } catch (err) {
        if (err.message) {
          this.alert.message = err.message;
          this.alert.show = true;
          this.buttonDisabled = false;
        }
      }
    },
  },
};
</script>
