import { datadogRum } from '@datadog/browser-rum';

export default class DatadogLogger {

    constructor() {
        this.datadogRum = datadogRum;
        this.datadogRum.init({
            applicationId: 'ba42f9d0-269f-42f1-84ea-536aab48a694',
            clientToken: 'pub383eda3ff63fc1b84588b8184c561fc6',
            site: 'us5.datadoghq.com',
            service: 'client-dashboard',
            env: process.env.NODE_ENV,
            sessionSampleRate: 100,
            version: '1.0.0',
            sessionReplaySampleRate: 100,
            telemetrySampleRate: 100,
            startSessionReplayRecordingManually: true,
            trackUserInteractions: true,
            defaultPrivacyLevel: 'mask-user-input'
        });
    }
}