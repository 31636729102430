<template>
  <w-form
    :name="this.$route.name"
    title="Recover Password"
    sub-title="Create a strong password."
  />
</template>

<script>
import wForm from "@/components/form/Form";

export default {
  name: "new-password",
  components: {
    wForm,
  },
};
</script>
