import { BaseService } from "./base.service";
import { ErrorWrapper, ResponseWrapper } from "./util";

export class TransactionService extends BaseService {
  static async getNextShipment(prescriptionId) {
    try {
      const response = await this.request({ auth: true }).put(
        `/transact/prescriptionorder/${prescriptionId}/getnext`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
  static async reorderPrescriptionOrder(prescriptionId) {
    try {
      const response = await this.request({ auth: true }).put(
        `/transact/prescriptionorder/${prescriptionId}/reorder`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
  static async skipPrescriptionOrder(prescriptionId) {
    try {
      const response = await this.request({ auth: true }).put(
        `/transact/prescriptionorder/${prescriptionId}/skip`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
  static async cancelPrescriptionOrder(prescriptionId) {
    try {
      const response = await this.request({ auth: true }).delete(
        `/transact/prescriptionorder/${prescriptionId}/cancel`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
  static async pauseSubscription(patientId, data) {
    try {
      const response = await this.request({ auth: true }, "v1").put(
        `/transact/patient/${patientId}/pause`,
        data
      );
      console.log("response", response);
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      console.log("error", error);
      throw new ErrorWrapper(error);
    }
  }
  static async preventCancelSubscription(patientId) {
    try {
      const response = await this.request({ auth: true }).put(
        `/transact/patient/${patientId}/preventcancel`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
  static async cancelSubscription(patientId, data) {
    try {
      const response = await this.request({ auth: true }, "v1").delete(
        `/transact/patient/${patientId}/cancel`,
        {
          data,
        }
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
  static async checkout(data) {
    try {
      const response = await this.request({ auth: true }).post(
        `/transact/checkout`,
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
  static async computeTax(data) {
    try {
      const response = await this.request({ auth: true }).post(
        `/transact/computeTax`,
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
