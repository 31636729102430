<template>
  <b-col cols="12" class="cart-login">
    <b-row align-v="stretch" class="py-5" style="height: 100%">
      <b-col cols="12" align-self="center" class="text-center">
        <h1 class="heading mb-4 text-center">
          Success! Your payment has been processed and your order is complete.
        </h1>
        <b-button
          variant="primary"
          pill
          @click="proceedOrder"
          style="min-width: 300px"
          >View your order</b-button
        >
      </b-col>
      <b-col cols="12" align-self="end" class="text-center">
        <p class="bold mb-2">Have a question?</p>
        <p>
          Please go to our
          <a
            href="javascript:void(0)"
            @click="
              window.href.location = `${process.env.VUE_APP_WEBSITE_URL}/support`
            "
            >Help Center</a
          >
          where you get answers to commonly asked questions about billing,
          shipment status, etc.
        </p>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import config from "../../../config";

export default {
  methods: {
    proceedOrder() {
      window.localStorage.setItem("cart", "");
      this.$emit("clear-cart");

      let urlString = `${process.env.VUE_APP_SITE_URL}/treatment`;
      let params = {
        content: urlString,
      };
      if (window.parent) {
        window.parent.postMessage(params, process.env.VUE_APP_WEBSITE_URL);
      }
    },
  },
};
</script>
