<template>
  <b-container fluid>
    <b-row align-v="center" align-h="center" style="height: 100vh">
      <b-col cols="12" sm="12" md="5" lg="5" xl="5" class="text-center">
        <img src="@/assets/images/Cancel.svg" alt="" />
        <p class="text-muted text-uppercase my-3">
          <small>{{ this.$route.name }} ERROR</small>
        </p>
        <h1>There’s no page here</h1>
        <p class="text-muted font-weight-bold">
          Looks like you ended up here by accident?
        </p>
        <router-link to="/" custom v-slot="{ navigate }"
          ><a
            href="javascript:void(0)"
            @click="navigate"
            class="btn btn-lg btn-primary mb-3"
          >
            Return to your dashboard</a
          ></router-link
        >
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "NotFound",
};
</script>
