<template>
  <b-col cols="12" class="cart-account">
    <b-row align-v="stretch" style="height: 100%">
      <!-- <b-col cols="12" align-self="start" class="logo pt-3">
        <img src="@/assets/images/icon-logo.svg" width="40">
      </b-col> -->
      <b-col cols="12" align-self="start" class="mt-6"
        ><h1 class="heading h1 color-inherit">Hello, {{ firstname }}</h1></b-col
      >
      <b-col cols="12" align-self="start">
        <b-row>
          <b-col cols="7" sm="9" md="10" xl="10"></b-col>
          <b-col cols="5" sm="3" md="2" xl="2">
            <ul class="list-unstyled">
              <li>
                <a
                  class="color-inherit"
                  href="javascript:void(0);"
                  @click="redirectTo('app', 'account-settings')"
                  ><h2>Profile</h2></a
                >
              </li>
              <li>
                <a
                  class="color-inherit"
                  href="javascript:void(0);"
                  @click="redirectTo('app', 'treatment')"
                  ><h2>Subscriptions</h2></a
                >
              </li>
              <li>
                <a
                  class="color-inherit"
                  href="javascript:void(0);"
                  @click="redirectTo('app', 'message')"
                  ><h2>Messages</h2></a
                >
              </li>
              <li>
                <a
                  class="color-inherit"
                  href="javascript:void(0);"
                  @click="redirectTo('website', 'support')"
                  ><h2>Support</h2></a
                >
              </li>
              <li>
                <a
                  class="color-inherit"
                  href="javascript:void(0);"
                  @click="redirectTo('website', 'contact')"
                  ><h2>Contact Us</h2></a
                >
              </li>
              <li class="mt-6">
                <a
                  class="color-inherit"
                  href="javascript:void(0);"
                  @click="logOut"
                  ><h2>Log Out</h2></a
                >
              </li>
            </ul>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
export default {
  props: {
    firstname: {
      type: String,
      default: "",
    },
  },
  methods: {
    redirectTo(location, url) {
      let urlString;
      if (location == "app") {
        urlString = `${process.env.VUE_APP_SITE_URL}/${url}`;
      } else {
        urlString = `${process.env.VUE_APP_WEBSITE_URL}/${url}`;
      }
      let params = {
        content: urlString,
      };
      if (window.parent) {
        window.parent.postMessage(params, process.env.VUE_APP_WEBSITE_URL);
      }
    },
    logOut() {
      this.$emit("logout");
    },
  },
};
</script>
