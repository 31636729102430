<template>
  <w-form 
    :name="this.$route.name" 
    title="Recover Password" 
    sub-title="Don’t worry, happens to the best of us."
  />
</template>

<script>
import wForm from '@/components/form/Form'

export default {
  name: 'forgot-password',
  components: {
    wForm
  }
}
</script>